import { getFeatureFlagValue } from '../../common/featureFlags/helpers';
import { IFinanceManagerFeatures } from './interface';

export const financeManagerFeatures: IFinanceManagerFeatures = {
    isFA201AutoSaveFeedBills: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA201_AUTO_SAVE_FEEDBILLES,
    ),
    isFA633DateValidation: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA633_DATE_VALIDATION_IN_FORMS,
    ),
    isFA636UpdateCloseHedgePage: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA636_UPDATE_CLOSE_HEDGE_PAGE,
    ),
    isFA625PullingWrongTemplate: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA625_PULLING_WRONG_Template,
    ),
    isFA740ApiCallOptimized: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA740_API_CALL_OPTIMIZED,
    ),
    isFA507FixPrepaidTotal: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA507_FIX_PREPAID_TOTAL,
    ),
    isFA727SidetabButton: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA727_SIDE_TAB_BUTTON,
    ),
    isFA748HedgeProfitFieldIssue: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA748_HEDGE_PROFIT_FIELD_ISSUE,
    ),
    isFA722WarehousingAppLabel: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA722_WAREHOUSING_APP_LABEL,
    ),
    isFA747ClosedLotPurchaseRecordNonEditable: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_FA747_CLOSED_LOT_PURCHASE_RECORD_NON_EDITABLE,
    ),
};
