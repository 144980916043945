import { getFeatureFlagValue } from '../../common/featureFlags/helpers';
import { IFeedlotManagerFeatures } from './interface';

export const feedlotManagerFeatures: IFeedlotManagerFeatures = {
    isCA12776RationMarkupColumnAdded: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12776_RATION_MARKUP_COLUMN_ADDED,
    ),
    isCA12950UpdateOwnerFieldToIsActive: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12950_UPDATE_OWNER_FIELD_TO_IS_ACTIVE,
    ),
    isCA12766MakeEstHeadModalIntuitive: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12766_MAKE_EST_HEAD_MODAL_INTUITIVE,
    ),
    isCA14087On_ScaleTicketOdataQueryBug: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA14087_SCALE_TICKET_ODATA_QUERY_BUG,
    ),
    isCA11787ToggleButtonOnLotSummary: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11787_TOGGLE_BUTTON_ON_LOT_SUMMARY,
    ),
    isCA9919On_ManageUsersPage: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9919_MANAGE_USERS_PAGE,
    ),
    isCA10241On_OrderTreatmentHistoryTable: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10241_ORDER_TREATMENT_HISTORY_TABLE,
    ),
    isCA12040_ADG_Lifetime: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12040_ADG_LIFETIME,
    ),
    isCA10953On_ProcessingInInvoicePeriod: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10953_PROCESSING_IN_INVOICE_PERIOD,
    ),
    isCA9148On_GetInactiveItems: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9148_TREATMENT_GET_INACTIVE_ITEMS,
    ),
    isCA11982On_OnlyOpenLotMFE: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11982_ONLY_OPEN_LOT_MFE,
    ),
    isCA7794On_TreatmentHistoricalGiveRecords: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA7794_TREATMENT_HISTORICAL_GIVE_RECORDS,
    ),
    isCA9656On_AllowZeroProcessingDose: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9656_ALLOW_ZERO_PROCESSING_DOSE,
    ),
    isCA13790On_AbstractBigJs: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA13790_ABSTRACT_BIG_JS,
    ),
    isCA7788On_RefundCancellationPolicy: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA7788_REFUND_CANCELLATION_POLICY,
    ),
    isCA13360On_FixUserManagementTableHeading: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA13360_FIX_USER_MANAGEMENT_TABLE_HEADING,
    ),
    isCA8488On_LbsToTonsToggle: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA8488_LBS_TO_TONS_TOGGLE,
    ),
    isCA13014On_ShowFeedingDashboard: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA13014_SHOW_FEEDING_DASHBOARD,
    ),
    isCA11811On_GenericReportsControl: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11811_GENERIC_REPORTS_CONTROL,
    ),
    isCA11604On_ScaleTicketUsesReportsApi: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11604_SCALE_TICKET_USES_REPORTS_API,
    ),
    isCA12190On_Reports_CommodityPricing: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12190_REPORTS_COMMODITY_PRICING,
    ),
    isCA9204On_BetterErrors_GeneratedInvoicePeriod: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA9204_BETTER_ERRORS_GENERATED_INVOICE_PERIOD,
    ),
    isCA13320On_BetterErrors_DeleteReceiveNegativeHead: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA13320_BETTER_ERRORS_DELETE_RECEIVE_NEGATIVE_HEAD,
    ),
    isCA13349ON_BetterErrors_BackdateShip: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA13349_BETTER_ERRORS_BACKDATE_SHIP,
    ),
    isCA13350On_BetterErrors_BackdateRealizer: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA13350_BETTER_ERRORS_BACKDATE_REALIZER,
    ),
    isCA13351On_BetterErrors_BackdateMortality: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA13351_BETTER_ERRORS_BACKDATE_MORTALITY,
    ),
    isCA12291On_Reports_CommodityUsageUsesReportsAPI: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA12291_REPORTS_COMMODITY_USAGE_USES_REPORTS_API,
    ),
    isCA12027On_MortalityPDFReport: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12027_MORTALITY_PDF_REPORT,
    ),
    isCA12645On_ProtectedReports: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12645_PROTECTED_REPORTS,
    ),
    isCA13333On_InformationalTooltipComponent: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA13333_INFORMATIONAL_TOOLTIP_COMPONENT,
    ),
    isCA11810On_Reports_Treatments: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11810_REPORTS_TREATMENTS,
    ),
    isCA12518On_Reports_LoadedCommodityVariance: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12518_REPORTS_LOADED_COMMODITY_VARIANCE,
    ),
    isCA12247On_Reports_MedicalItemPricing: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12247_REPORTS_MEDICAL_ITEM_PRICING,
    ),
    isCA11210On_Reports_ScheduleAndRationChange: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA11210_REPORTS_SCHEDULE_AND_RATION_CHANGE,
    ),
    isCA13603On_ExcelReportsSharedFunction: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA13603_EXCEL_REPORTS_SHARED_FUNCTION,
    ),
    isCA11014On_FMDashboard: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11014_FM_DASHBOARD,
    ),
    isCA12649On_FixTabbingDateScaleTicket: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12649_FIX_TABBING_DATE_SCALE_TICKET,
    ),
    isCA13269On_LongReportTimeouts: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA13269_LONG_REPORT_TIMEOUTS,
    ),
    isCA13451On_UpdateDashboardHealthAndMovement: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA13451_UPDATE_DASHBOARD_HEALTH_AND_MOVEMENT,
    ),
    isCA12984On_AddHotCattleInLotButton: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12984_ADD_HOT_CATTLE_IN_LOT_BUTTON,
    ),
    isCA10329On_DisplayHospPenOnShipCattle: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10329_DISPLAY_HOSP_PEN_ON_SHIP_CATTLE,
    ),
    isCA11983On_MfeNecessaryPayload: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11983_MFE_NECESSARY_PAYLOAD,
    ),
    isCA12668On_FixFeedCallGraphOnScheduleChange: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA12668_FIX_FEED_CALL_GRAPH_ON_SCHEDULE_CHANGE,
    ),
    isCA12467On_ShowHospitalAndMoveToPensRations: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA12467_SHOW_HOSPITAL_AND_MOVETO_PENS_RATIONS,
    ),
    isCA12681On_Reports_Loader: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12681_REPORTS_LOADER,
    ),
    isCA12823On_Reports_DriverDeliveryVariance: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12823_REPORTS_DRIVER_DELIVERY_VARIANCE,
    ),
    isCA12491On_Reports_VetYardSheet: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12491_REPORTS_VET_YARD_SHEET,
    ),
    isCA12251On_Reports_PenRider: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12251_REPORTS_PEN_RIDER,
    ),
    isCA12516On_ShowCurrentAndMoveToPensRations: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA12516_SHOW_CURRENT_AND_MOVETO_PENS_RATIONS,
    ),
    isCA12547On_ExcludeEstimatedHeadsInMfe: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12547_EXCLUDE_ESTIMATED_HEADS_IN_MFE,
    ),
    isCA12847On_FixPRSModalDropdownAlignment: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12847_FIX_PRS_MODAL_DROPDOWN_ALIGNMENT,
    ),
    isCA12888On_IncludeLotDetailsInFeedCall: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12888_INCLUDE_LOT_DETAILS_IN_FEED_CALL,
    ),
    isCA12065On_EnableQuickBooks: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA12065_ENABLE_QUICKBOOKS_STATUS_IN_INVOICE_LIST,
    ),
    disableOldFeedCall: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_DISABLE_OLD_FEED_CALL,
    ),
    isCA13003On_SendOutTimingsInFeedlotTimezoneFeedStatus: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA13003_SEND_OUT_TIMINGS_IN_FEEDLOTTIMEZONE_FEEDSTATUS,
    ),
    isCA12279On_FixTreatmentReportDateRange: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12279_FIX_TREATMENT_REPORT_DATE_RANGE,
    ),
    isCA13323On_FixEmptyAuditLog: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA13323_FIX_EMPTY_AUDIT_LOG,
    ),
    isCA12499On_FixSubLotDetailsOnPRS: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12499_FIX_SUBLOT_DETAILS_ON_PRS,
    ),
    isCA13747On_LimitFeedHistoryTableRecords: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA13747_LIMIT_FEED_HISTORY_RECORDS,
    ),
    isCA11466On_EditOwnerWhenever: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11466_EDIT_OWNER_WHENEVER,
    ),
    isCA11590_EIDIncludeMedicalItems: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11590_EID_INCLUDE_MEDICAL_ITEMS,
    ),
    isCA11435On_EIDOnExistingLists: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11435_EID_ON_EXISTING_LISTS,
    ),
    isCA12360On_VarianceRoundingIssue: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12360_VARIANCE_ROUNDING_ISSUE,
    ),
    isCA10154On_PreventDoubleSaving: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10154_PREVENT_DOUBLE_SAVING,
    ),
    isCA8055On_CancelNavigation: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA8055_CANCEL_NAVIGATION,
    ),
    isCA10204On_PreventDoubleSavingReceiveCattleFooter: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA10204_PREVENT_DOUBLE_SAVING_RECEIVE_CATTLE_FOOTER,
    ),
    isCA9800On_ContractsMVP: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9800_CONTRACTS_MVP,
    ),
    isCA13805On_CommodityInventoryHistoryFilter: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA13805_COMMODITY_INVENTORY_HISTORY_FILTER,
    ),
    isCA12412On_AssignFeedChargeBugs: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12412_ASSIGN_FEED_CHARGE_BUGS,
    ),
    isCA11760On_Reports_FilterScaleTicketsByVendor: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA11760_REPORTS_FILTER_SCALE_TICKETS_BY_VENDOR,
    ),
    isCA11761On_Reports_Realizers: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11761_REPORTS_REALIZERS,
    ),
    isCA11809On_Reports_Ship: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11809_REPORTS_SHIP,
    ),
    isCA11808On_Reports_Receive: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11808_REPORTS_RECEIVE,
    ),
    isCA11766On_VendorScaleTicketReportPrettyPdf: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA11766_VENDOR_SCALE_TICKET_REPORT_PRETTY_PDF,
    ),
    isCA11762On_Reports_InvoicesChargeSummary: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11762_REPORTS_INVOICES_CHARGE_SUMMARY,
    ),
    isCA11763On_Reports_CommodityBilling: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11763_REPORTS_COMMODITY_BILLING,
    ),
    isCA10418On_EnhanceScaleTicketsExports: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10418_ENHANCE_SCALE_TICKETS_EXPORTS,
    ),

    isCA12223On_BackdatingProtection_RationMarkup: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA12223_BACKDATING_PROTECTION_RATION_MARKUP,
    ),
    isCA12219On_BackdatingProtection_MedicalItems: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA12219_BACKDATING_PROTECTION_MEDICAL_ITEMS,
    ),
    isCA12218On_BackdatingProtection_Commodities: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12218_BACKDATING_PROTECTION_COMMODITIES,
    ),
    isCA11386On_EIDOnExistingReports: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11386_EID_ON_EXISTING_REPORTS,
    ),
    isCA8191On_DatabricksRowUpdate: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA8191_DATABRICKS_ROW_UPDATE,
    ),
    is8642On_MakeTreatmentProtocolsEditable: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA8642_MAKE_TREATMENT_PROTOCOLS_EDITABLE,
    ),
    is8643On_MakeProcessingProtocolsEditable: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA8643_MAKE_PROCESSING_PROTOCOLS_EDITABLE,
    ),
    isCA12972On_RefreshFeedStatus: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12972_REFRESH_FEED_STATUS,
    ),
    isCA8348On_PenHistoryModal: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA8348_ENABLE_PEN_HISTORY_MODAL,
    ),
    isCA8683On_AnimalIdAutocompleteDropdownFix: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA8683_ANIMAL_ID_AUTOCOMPLETE_DROPDOWN_FIX,
    ),
    isCA8390On_DisplayReleventBillingPeriods: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA8390_DISPLAY_RELEVENT_BILLING_PERIODS,
    ),
    isCA10398On_AnimalDropdownBareText: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10398_ANIMAL_DROPDOWN_BARE_TEXT,
    ),
    isCA8591On_DeleteGiveMedItems: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA8591_DELETE_GIVE_MED_ITEMS,
    ),
    isCA4898On_LoaderList: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA4898_SETTINGS_LOADER_LIST,
    ),
    isCA9089On_RestrictGiveTreatmentSameMedicalItem: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA9089_RESTRICT_GIVE_TREATMENT_SAME_MEDICAL_ITEM,
    ),
    isCA7808On_PreventFutureDateScaleTicket: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA7808_PREVENT_FUTURE_DATE_SCALE_TICKET,
    ),
    isCA5440On_TechAndSeverityBug: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA5440_TECH_AND_SEVERITY_BUG,
    ),
    isCA10570On_VendorList: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10570_SETTINGS_VENDOR_LIST,
    ),
    isCA10320On_EditDeletePenSettings: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10320_SETTINGS_PEN_EDIT_DELETE,
    ),
    isCA11980On_ProcessingCSSFixes: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11980_PROCESSING_CSS_FIXES,
    ),
    isCA10540AddedRationNameColumn: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10540_ADDED_RATION_NAME_COLUMN,
    ),
    isCA10543_RenamingRations: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10543_RENAMING_RATIONS,
    ),
    isCA11407OnRemoveDailyCommodityRecordsButton: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA11407_REMOVE_DAILY_COMMODITY_RECORDS_BUTTON,
    ),
    isCA11016On_EIDMortalities: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11016_EID_MORTALITIES,
    ),
    isCA10935On_TreatmentRevamp: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10935_TREATMENT_REVAMP,
    ),
    isCA11492On_OldLotsLoadOnMortalities: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11492_OLD_LOTS_LOAD_ON_MORTALITIES,
    ),
    isCA12519On_Reports_DailyCharges: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12519_REPORTS_DAILY_CHARGES,
    ),
    isCA12213On_Reports_MedicalInventory: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12213_REPORTS_MEDICAL_INVENTORY,
    ),
    isCA12374On_DisableAvgAsFedReport: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12374_DISABLE_AVG_AS_FED_REPORT,
    ),
    isCA8929On_LocalUTCDateTimeFix: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA8929_LOCAL_UTC_DATE_TIME_FIX,
    ),
    isCA12277On_Reports_DateRangeFix: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12277_REPORTS_DATE_RANGE_FIX,
    ),
    isCA9558On_ProdAlertMortalityGet502: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9558_PROD_ALERT_MORTALITY_GET_502,
    ),
    isCA12694On_SettingsForAdminAndFeedlotManagerFix: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA12694_SETTINGS_FOR_ADMIN_AND_FEEDLOT_MANAGER_FIX,
    ),
    isCA13566On_DashboardAdditions: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA13566_DASHBOARD_ADDITIONS,
    ),
    isCA8675On_LiquidAndMicroingredientsCommodities: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA8675_LIQUID_MICROINGREDIENT_COMMODITIES,
    ),
    isCA9674On_LoadedCommodityReportEnhancement: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA9674_LOADED_COMMODITY_REPORT_ENHANCEMENT,
    ),
    isCA12931On_HideInactiveMedicalLots: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12931_HIDE_INACTIVE_MEDICAL_LOTS,
    ),
    isCA10313On_HospitalPageUnassociatedHomePen: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA10313_HOSPITAL_PAGE_UNASSOCIATED_HOME_PEN,
    ),
    isCA9756On_AllowSameIngredientsRationCreation: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA9756_ALLOW_SAME_INGREDIENTS_MULTIPLE_TIMES_IN_RATION_CREATION,
    ),
    isCA9963OnMFE2RationDropdownFix: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9963_MFE2_RATION_DROPDOWN_FIX,
    ),
    isCA9436On_LoaderCommodityReportEnhancement: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA9436_LOADER_COMMODITY_REPORT_ENHANCEMENT,
    ),
    isCA10070OnSpiltScheduleInsertConflict: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10070_SPILT_SCHEDULE_INSERT_CONFLICT,
    ),
    isCA12765On_TreatmentUserChangingCurrentPenToHospital: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA12765_TREATMENT_USER_CHANGING_CURRENT_PEN_TO_HOSPITAL,
    ),
    isCA10054On_CommodityCosting: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10054_COMMODITY_COSTING,
    ),
    isCA10260On_duplicateRationSchedule: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10260_DUPLICATE_RATION_SCHEDULE_ROWS,
    ),
    isCA10428On_MovementHistoryReportModal: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10428_MOVEMENT_HISTORY_REPORT_MODAL,
    ),
    isCA10368on_scheduleRationChangeReport: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10368_SCHEDULE_RATION_CHANGE_REPORT,
    ),
    isCA12517On_Reports_FeedByLot: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12517_REPORTS_FEED_BY_LOT,
    ),
    isCA12688On_Reports_ActualVsExpectedFeedCharges: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA12688_REPORTS_ACTUAL_VS_EXPECTED_FEED_CHARGES,
    ),
    isCA12822On_Reports_FeedOutOfVariance: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12822_REPORTS_FEED_OUT_OF_VARIANCE,
    ),
    isCACA13778On_HospitalPageSubmitBug: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA13778_HOSPITAL_PAGE_SUBMIT_BUG,
    ),
    isCA10472On_FeedStatus2AdvanceSorting: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10472_FEED_STATUS_2_ADVANCE_SORTING,
    ),
    isCA10455On_FeedCallRationChangeReminderModal: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA10455_FEEDCALL_RATION_CHANGE_REMINDER_MODAL,
    ),
    isCA10333ReduceInfoInRequestResponseForMFE: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA10333_REDUCE_INFO_IN_REQUEST_RESPONSE_FOR_MFE,
    ),
    isCA10586On_refactorFeedCall: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10586_REFACTOR_FEED_CALL,
    ),
    isCA13962On_ExportHospitalDoubleDownloadBug: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA13962_EXPORT_HOSPITAL_DOUBLE_DOWNLOAD_BUG,
    ),
    isCA13303On_BetterErrorsForDuplicateRecords: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA13303_BETTER_ERRORS_FOR_DUPLICATE_RECORDS,
    ),
    isCA12974On_FixCommodityAdjustmentFilter: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12974_FIX_COMMODITY_ADJUSTMENT_FILTER,
    ),
    isCA10751On_FeedCallSamApiForGraphAndTable: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA10751_FEEDCALL_SAME_API_FOR_GRAPH_AND_TABLE,
    ),
    isCA10761On_FeedCallEstHeadCountModalFix: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10761_FEEDCALL_EST_HEAD_COUNT_MODAL_FIX,
    ),
    isCA11188On_MoveCattleFormReset: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11188_MOVE_CATTLE_FORM_RESET,
    ),
    isCA12990On_UpdateTotalCallAfterSaveEstHd: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA12990_UPDATE_TOTAL_CALL_AFTER_SAVE_EST_HD,
    ),
    isCA11189On_FeedCallRationScheduleModalFix: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA11189_FEEDCALL_RATION_SCHEDULE_MODAL_FIX,
    ),
    isCA11177On_FeedCallRouteAbilityAddPen: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11177_FEEDCALL_ROUTE_ABILITY_ADD_PEN,
    ),
    isCA11160n_OwnerContextApiCall: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11160_OWNER_CONTEXT_API_CALL,
    ),
    isCA114950n_ReceiveCattleDateFilterFix: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA11495_RECEIVE_CATTLE_FILTER_DATE_FILTER_FIX,
    ),
    isCA11561On_FetchRationInMfeWithDate: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11561_FETCH_RATION_WITH_DATE_FOR_MFE,
    ),
    isCA13317On_HealthManagerCowboyTreatmentConflict: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA13317_HEALTH_MANAGER_COWBOY_TREATMENT_CONFLICT,
    ),
    isCA10960_RationScheduleTemplateDeleteOnSave: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA10960_RATION_SCHEDULE_TEMPLATE_DELETE_ON_SAVE,
    ),
    isCA11870On_RedBorderForDuplicateTemplateName: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA11870_RED_BORDER_FOR_DUPLICATE_TEMPLATE_NAME,
    ),
    isCA12930On_BlankCurrentInventory: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12930_BLANK_CURRENT_INVENTORY,
    ),
    isCA11699On_FeedCallTabClickLoopBack: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11699_FEEDCALL_TAB_CLICK_LOOP_BACK,
    ),
    isCA11910On_VariableSplitExitingTemplateDeleteFix: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA11910_VARIABLE_SPLIT_EXISTING_TEMPLATE_DELETE_FIX,
    ),
    isCA9481On_FeedCallBunkScoreHighlight: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9481_FEEDCALL_BUNK_SCORE_HIGHLIGHT,
    ),
    isCA13134On_DashboardCleanup: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA13134_DASHBOARD_CLEANUP,
    ),
    isCA12041On_FeedChargeAllowHdCount: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12041_FEED_CHARGE_ALLOW_HD_COUNT,
    ),
    isCA12045On_FeedStatusClearFilterButton: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12045_FEED_STATUS_CLEAR_FILTER_BUTTON,
    ),
    isCA11859On_NewMiscChargeLotTypeAhead: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11859_NEW_MISC_CHARGE_LOT_TYPE_AHEAD,
    ),
    isCA12445On_PenRationScheduleFixPreviousPenTemplate: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA12463_PEN_RATION_SCHEDULE_FIX_PREVIOUS_PEN_TEMPLATE,
    ),
    isCA12554On_CreateScaleTicketWithBadContractDataBugFix: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA12554_CREATE_SCALE_TICKET_WITH_BAD_CONTRACT_DATA_BUG_FIX,
    ),
    isCA12775On_CreateScaleTicketTabbingDisablesContractFix:
        getFeatureFlagValue(
            process.env
                .REACT_APP_FEATURE_CA12775_CREATE_SCALE_TICKET_TABBING_DISABLES_CONTRACT_FIX,
        ),
    isCA12552On_ScaleTicketTrucingCompanyNotRequired: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12552_TRUCKING_COMPANY_NOT_REQUIRED,
    ),
    isCA12609On_HighlightRowInFeedHistory: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12609_HIGHLIGHT_ROW_IN_FEED_HISTORY,
    ),
    isCA13076On_OwnerActiveCheckboxFix: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA13076_OWNER_ACTIVE_CHECKBOX_FIX,
    ),
    isCA11926On_FeedCallRouteReverseOrder: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11926_FEEDCALL_ROUTE_REVERSE_ORDER,
    ),
    isCA12794On_ShipCattleFormChanges: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12794_SHIP_CATTLE_FORM_CHANGES,
    ),
    isCA12699On_PenHistoryModalMfeDisplayDifferentLots: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA12699_PEN_HISTORY_MODAL_MFE_DISPLAY_DIFFERENT_LOTS,
    ),
    isCA10985On_MoreUserFeedbackForCantDeleteRecieveRecord: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA10985_MORE_USER_FEEDBACK_FOR_CANT_DELETE_RECEIVE_RECORD,
    ),
    isCA12375On_NewDiagnosisDoesNotShowUpBugFix: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA12375_ON_NEW_DIAGNOSIS_DOES_NOT_SHOW_UP_BUG_FIX,
    ),
    isCA12455On_ShipCattleRealizerDateFilterRange: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA12455_SHIP_CATTLE_REALIZER_DATE_FILTER_RANGE,
    ),
    isCA12722On_CommodityLatestUpdatedHistoryFirst: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA12722_COMMODITY_LATEST_UPDATED_HISTORY_FIRST,
    ),
    isCA13063On_FixCommodityAdjustmentHistory: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA13063_FIX_COMMODITY_ADJUSTMENT_HISTORY,
    ),
    isCA13135On_PastInvoiceSortRecords: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA13135_PAST_INVOICE_SORT_RECORDS,
    ),
    isCA13466On_AddNoFeedCallOptionToRationFilter: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA13466_ADD_NO_FEED_CALL_OPTION_TO_RATION_FILTER,
    ),
    isCA13152On_LotSummaryChangeFilterToSingleSelect: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_LOT_SUMMARY_CHANGE_FILTER_TO_SINGLE_SELECT,
    ),
    isCA12879On_ShipReceiveCattlePenHistoryModal: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA12879_SHIP_RECEIVE_CATTLE_PEN_HISTORY_MODAL,
    ),
    isCA13154On_NoCallApiWhenDateIsReset: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA13154_NO_CALL_API_WHEN_DATE_IS_RESET,
    ),
    isCA13054On_NgatFmNewLogoIcon: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA13054_NGAT_FM_NEW_LOGO_ICON,
    ),
    isCA12191On_HidePowerBiRefreshButton: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12191_HIDE_POWER_BI_REFRESH_BUTTON,
    ),
    isCA12627On_MoveCattleAuditLogs: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12627_MOVE_CATTLE_AUDIT_LOGS,
    ),
    isCA13231On_RealizerOwnerChangeFixClear: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA13231_REALIZER_OWNER_CHANGE_FIX_CLEAR,
    ),
    isCA13229On_ShipRealzerShipNewCattleOutOfHospitalPenNotAllowed:
        getFeatureFlagValue(
            process.env
                .REACT_APP_FEATURE_CA13229_SHIP_REALIZER_SHIP_NEW_CATTLE_OUT_OF_HOSPITAL_PEN_NOT_ALLOWED,
        ),
    isCA13386On_RealizerCreateDestinationCancelFix: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA13386_REALIZER_CREATE_DESTINATION_CANCEL_FIX,
    ),
    isCA13266On_InfoModalOnBackdatedRecords: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA13266_INFO_MODAL_BACKDATED_RECORDS,
    ),
    isCA12073On_QuickBooksIntegration: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12073_QUICKBOOKS_INTEGRATION,
    ),
    isCA13732On_BackDateRationIncorrectTimestamp: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA13732_BACK_DATE_RATION_INCORRECT_TIMESTAMP,
    ),
    isCA14058On_FeedCallAfDmChangeBugAndOtherBugs: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA14058_FEEDCALL_AF_DM_CHANGE_BUG_AND_OTHER_BUGS,
    ),
    isCA13465On_DisableSaveAndNextForPendingTotalCall: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA13465_DISABLE_SAVE_AND_NEXT_FOR_PENDING_TOTAL_CALL,
    ),
    isCA13374On_LimitReportsToOneYear: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA13374_LIMIT_REPORTS_TO_ONE_YEAR,
    ),
    isCA12810On_BillingNoDateRangeDisabledInvoiceButton: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA12810_BILLING_NO_DATE_RANGE_DISABLED_INVOICE_BUTTON,
    ),
    isCA10901On_ReduceAPICallsForShipCattle: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10901_REDUCE_API_CALLS_FOR_SHIP_CATTLE,
    ),
    isCA13649On_EditScaleTicketClearingContract: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA13649_EDIT_SCALE_TICKET_CLEARING_CONTRACT,
    ),
    isCA13723On_EditRealizerLoadValuesForClosedLots: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA13723_EDIT_REALIZER_LOAD_VALUES_FOR_CLOSED_LOTS,
    ),
    isCA13628_PreventReceiveCattleListAPIResonseRaceCondition:
        getFeatureFlagValue(
            process.env
                .REACT_APP_FEATURE_CA13628_PREVENT_RECEIVE_CATTLE_LIST_API_RESPONSE_RACE_CONDITION,
        ),
    isCA13819_ShipRealizerClearLotNotClearingOtherFields: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA13819_SHIP_REALIZER_CLEAR_LOT_NOT_CLEARING_OTHER_FIELDS,
    ),
    isCA12654On_CommoditiesLoadedVarianceFilterByDateRange: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA12654_COMMODITIES_LOADED_VARIANCE_FILTER_BY_DATE_RANGE,
    ),
    isCA13781On_ShipAuditLogsIncorrectDescOrder: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA13781_SHIP_AUDIT_LOGS_INCORRECT_DESC_ORDER,
    ),
    isCA13296On_DownloadExcelReportWithRequest: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA13296_DOWNLOAD_EXCEL_REPORT_WITH_REQUEST,
    ),
};
