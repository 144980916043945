export const CreateBuyPoActionTypes = {
    SET_GLOBAL_INFO_BUYPO: 'SET_GLOBAL_INFO_BUYPO',
    UPDATE_GLOBAL_INFO_BUYPO_ID: 'UPDATE_GLOBAL_INFO_BUYPO_ID',
    SET_GLOBAL_INFO_ERROR_BUYPO: 'SET_GLOBAL_INFO_ERROR_BUYPO',
    SET_COMPONENTS_STEP_STATE_BUYPO: 'SET_COMPONENTS_STEP_STATE_BUYPO',
    SET_ORDERS_STATE_BUYPO: 'SET_ORDERS_STATE_BUYPO',
    SET_ORDERS_AND_FEE_TOTAL_STATE_BUYPO:
        'SET_ORDERS_AND_FEE_TOTAL_STATE_BUYPO',
    SET_IS_ERROR_STATE_BUYPO: 'SET_IS_ERROR_STATE_BUYPO',
    SET_FEE_TYPES_STATE_BUYPO: 'SET_FEE_TYPES_STATE_BUYPO',
    SET_FEE_IDS_BUYPO: 'SET_FEE_IDS_BUYPO',
    SET_LAST_MODIFIED_DATE_BUYPO: 'SET_LAST_MODIFIED_DATE_BUYPO',
    SET_LAST_MODIFIED_DETAILS_BUYPO: 'SET_LAST_MODIFIED_DETAILS_BUYPO',
    SET_DESTINATION_DETAIL_BUY_PO: 'SET_DESTINATION_DETAIL_BUY_PO',
    SET_BUY_PO_LIST: 'SET_BUY_PO_LIST',
    APPEND_BUY_PO_LIST: 'APPEND_BUY_PO_LIST',
    SET_BUY_PO_FILTER: 'SET_BUY_PO_FILTER',
    APPEND_BUY_PO_FILTER: 'APPEND_BUY_PO_FILTER',
    SET_BUY_PO_PAYMENT_LOADING: 'SET_BUY_PO_PAYMENT_LOADING',
    SET_BUY_PO_LOADING: 'SET_BUY_PO_LOADING',
    SET_BUY_PO_CAN_CALL_SERVICE: 'SET_BUY_PO_CAN_CALL_SERVICE',
    SET_DELETE_BUY_PO_IDs: 'SET_DELETE_BUY_PO_IDs',
    SET_PAYMENT_INFO: 'SET_PAYMENT_INFO',
    SET_BUY_PO_FILE_DETAIL: 'SET_BUY_PO_FILE_DETAIL',
    SET_BUY_PO_SUBMITTED: 'SET_BUY_PO_SUBMITTED',
    RESET_ALL_BUY_PO_LIST_STATES: 'RESET_ALL_BUY_PO_LIST_STATES',
    RESET_ALL_BUY_PO_CREATE_STATES: 'RESET_ALL_BUY_PO_CREATE_STATES',
    SET_BUY_PO_EXCEL_RECORD: 'SET_BUY_PO_EXCEL_RECORD',
    SET_DATE_RANGE_BUYPO: 'SET_DATE_RANGE_BUYPO',
    SET_SHOULD_ALLOW_BUYPO_PAGE_CHANGE: 'SET_SHOULD_ALLOW_BUYPO_PAGE_CHANGE',
};

export const CreateReceiveCattleTypes = {
    SET_RECEIVE_CATTLE_DETAIL: 'SET_RECEIVE_CATTLE_DETAIL',
    SET_RECEIVE_CATTLE_PENS: 'SET_RECEIVE_CATTLE_PENS',
    SET_RECEIVE_CATTLE_DETAIL_ERROR: 'SET_RECEIVE_CATTLE_DETAIL_ERROR',
    SET_RECEIVE_CATTLE_PENS_ERROR: 'SET_RECEIVE_CATTLE_PENS_ERROR',
    SET_RECEIVE_CATTLE_FEES: 'SET_RECEIVE_CATTLE_FEES',
    SET_RECEIVE_CATTLE_FEES_ERROR: 'SET_RECEIVE_CATTLE_FEES_ERROR',
    SET_RECEIVE_CATTLE_FEE_DETAIL: 'SET_RECEIVE_CATTLE_FEE_DETAIL',
    SET_FEES_TOTAL: 'SET_FEES_TOTAL',
    SET_RECEIVE_CATTLE_LOADING: 'SET_RECEIVE_CATTLE_LOADING',
    SET_GENERATED_INVOICE_CUTOFF_DATE: 'SET_GENERATED_INVOICE_CUTOFF_DATE',
    SET_RECEIVE_CATTLE_LOTS: 'SET_RECEIVE_CATTLE_LOTS',
    SET_RECEIVE_CATTLE_OWNERS: 'SET_RECEIVE_CATTLE_OWNERS',
    SET_RECEIVE_CATTLE_ORIGINS: 'SET_RECEIVE_CATTLE_ORIGINS',
    SET_RECEIVE_CATTLE_REPORTS: 'SET_RECEIVE_CATTLE_REPORTS',
    SET_SUBMIT_PENDING: 'SET_SUBMIT_PENDING',
    SET_RECEIVE_BACKDATED_INFO_MODAL: 'SET_RECEIVE_BACKDATED_INFO_MODAL',
};

export const CreateRationTypes = {
    SET_RATION_LOADING: 'SET_RATION_LOADING',
    SET_RATION_INGREDIENTS: 'SET_RATION_INGREDIENTS',
    SET_RATION_INGREDIENTS_ERROR: 'SET_RATION_INGREDIENTS_ERROR',
    SET_RATION_FEE_DETAIL: 'SET_RATION_FEE_DETAIL',
    SET_INGREDIENTS_TOTAL: 'SET_INGREDIENTS_TOTAL',
    SET_PERCENTAGE_TOTAL: 'SET_PERCENTAGE_TOTAL',
    SET_DRY_MATTER_TOTAL: 'SET_DRY_MATTER_TOTAL',
    SET_RATION_DETAIL: 'SET_RATION_DETAIL',
    SET_RATION_DETAIL_ERROR: 'SET_RATION_DETAIL_ERROR',
    SET_INGREDIENTS: 'SET_INGREDIENTS',
    SET_ALL_RATIONS: 'SET_ALL_RATIONS',
    SET_IS_RATION_DETAIL_CHANGED: 'SET_IS_RATION_DETAIL_CHANGED',
    SET_SUBMIT_PENDING_CREATE_RATION: 'SET_SUBMIT_PENDING_CREATE_RATION',
    SET_ACTIVE_BUTTON_CONFIRMATION_MODAL:
        'SET_ACTIVE_BUTTON_CONFIRMATION_MODAL',
};

export const ShipCattleActionTypes = {
    FETCH_SHIP_CATTLE_GlOBAL_INFO: 'FETCH_SHIP_CATTLE_GlOBAL_INFO',
    FETCH_GLOBAL_LOT: 'FETCH_GLOBAL_LOT',
    FETCH_SHIP_CATTLE_LOT: 'FETCH_SHIP_CATTLE_LOT',
    FETCH_SHIP_CATTLE_LOAD_TICKETS: 'FETCH_SHIP_CATTLE_LOAD_TICKETS',
    FETCH_SHIP_CATTLE_TOTALS: 'FETCH_SHIP_CATTLE_TOTALS',
    FETCH_SHIP_CATTLE_COSTS: 'FETCH_SHIP_CATTLE_COSTS',
    FETCH_SHIP_CATTLE_LOCATION: 'FETCH_SHIP_CATTLE_LOCATION',
    SET_SHIP_CATTLE_HISTORY_LOADING: 'SET_SHIP_CATTLE_HISTORY_LOADING',
};

export const PenRationTemplatesActionTypes = {
    SET_PEN_RATION_SCHEDULE_TEMPLATES_LOADING:
        'SET_PEN_RATION_SCHEDULE_TEMPLATES_LOADING',
    SET_PEN_RATION_TEMPLATES: 'SET_PEN_RATION_TEMPLATES',
    APPEND_PEN_RATION_TEMPLATES: 'APPEND_PEN_RATION_TEMPLATES',
    SET_EXISTING_PEN_RATION_TEMPLATES: 'SET_EXISTING_PEN_RATION_TEMPLATES',
    SET_EXISTING_PEN_RATION_TEMPLATES_DETAILS:
        'SET_EXISTING_PEN_RATION_TEMPLATES_DETAILS',
    SET_SCHEDULE_TYPE: 'SET_SCHEDULE_TYPE',
    SET_EDIT_PEN_RATION_TEMPLATE_BASIC_DETAIL:
        'SET_EDIT_PEN_RATION_TEMPLATE_BASIC_DETAIL',
    SET_EDIT_PEN_RATION_TEMPLATE_BASIC_DETAIL_ERROR:
        'SET_EDIT_PEN_RATION_TEMPLATE_BASIC_DETAIL_ERROR',
    SET_EDIT_PEN_RATION_TEMPLATE_TABLE_RATIONS:
        'SET_EDIT_PEN_RATION_TEMPLATE_TABLE_RATIONS',
    SET_EDIT_PEN_RATION_TEMPLATES: 'SET_EDIT_PEN_RATION_TEMPLATES',
    SET_DELETED_PEN_RATION_TEMPLATES: 'SET_DELETED_PEN_RATION_TEMPLATES',
    SET_EDIT_PEN_RATION_TEMPLATES_ERROR: 'SET_EDIT_PEN_RATION_TEMPLATES_ERROR',
    RESET_PEN_RATION_SCHEDULE_TEMPLATES: 'RESET_PEN_RATION_SCHEDULE_TEMPLATES',
    SET_EDIT_PEN_RATION_TEMPLATE: 'SET_EDIT_PEN_RATION_TEMPLATE',
    SET_VARIABLE_SPLIT_RATION_TEMPLATE: 'SET_VARIABLE_SPLIT_RATION_TEMPLATE',
    SET_VARIABLE_SPLIT_RATION_TEMPLATES: 'SET_VARIABLE_SPLIT_RATION_TEMPLATES',
    SET_VARIABLE_SPLIT_TEMPLATE_ERROR:
        'SET_VARIABLE_SPLIT_RATION_TEMPLATE_ERROR',
    SET_VARIABLE_SPLIT_TEMPLATES_ERROR:
        'SET_VARIABLE_SPLIT_RATION_TEMPLATES_ERROR',
    SET_SELECTED_SCHEDULE_TYPE: 'SET_SELECTED_SCHEDULE_TYPE',
    SET_IS_NEW_PEN_RATION_SCHEDULE: 'SET_IS_NEW_PEN_RATION_SCHEDULE',
};

export const FeedCallRoutesActionTypes = {
    FETCH_FEED_CALL_ROUTES: 'FETCH_FEED_CALL_ROUTES',
    FETCH_FEED_CALL_ROUTES_PENS: 'FETCH_FEED_CALL_ROUTES_PENS',
    DELETE_FEED_CALL_ROUTES_ROW: 'DELETE_FEED_CALL_ROUTES_ROW',
    ADD_NEW_FEED_CALL_ROUTES_ROW: 'ADD_NEW_FEED_CALL_ROUTES_ROW',
    FETCH_FEED_CALL_ROUTES_DEFAULT_STATE:
        'FETCH_FEED_CALL_ROUTES_DEFAULT_STATE',
    FEED_CALL_ROUTES_SUBMIT_PENDING: 'FEED_CALL_ROUTES_SUBMIT_PENDING',
    IS_PEN_REVERSE_FEED_CALL_ORDER: 'IS_PEN_REVERSE_FEED_CALL_ORDER',
    REVERSE_FEEDCALL_ROUTE_FM_SETTING_ID:
        'REVERSE_FEEDCALL_ROUTE_FM_SETTING_ID',
};

export const FeedCallActionTypes = {
    SET_BUNK_SCORE_AF_CHANGE_DEFAULT: 'SET_BUNK_SCORE_AF_CHANGE_DEFAULT',
    SET_CURRENT_PEN_INDEX: 'SET_CURRENT_PEN_INDEX',
    FEED_CALL_SELECT_PEN_ID: 'FEED_CALL_SELECT_PEN_ID',
    FEED_CALL_SELECT_PEN_HD_COUNT: 'FEED_CALL_SELECT_PEN_HD_COUNT',
    SET_BUNK_SCORE_AF_CHANGE_PER_HD: 'SET_BUNK_SCORE_AF_CHANGE_PER_HD',
    FETCH_FEED_CALL_RATION_SCHEDULE: 'FETCH_FEED_CALL_RATION_SCHEDULE',
    SET_FEED_CALL_CURRENT_VALUE: 'SET_FEED_CALL_CURRENT_VALUE',
    SET_BUNK_SCORE_API_RESPONSE: 'SET_BUNK_SCORE_API_RESPONSE',
    FETCH_FEED_CALL_DETAILS: 'FETCH_FEED_CALL_DETAILS',
    ORIGINAL_FEED_CALL_ASSESSMENT: 'ORIGINAL_FEED_CALL_ASSESSMENT',
    FETCH_FEED_CALL_PENS: 'FETCH_FEED_CALL_PENS',
    SET_SELECTED_SCHEDULE_TEMPLATE: 'SET_SELECTED_SCHEDULE_TEMPLATE', //remove with isCA10586On_refactorFeedCall
    SET_FEED_CALL: 'SET_FEED_CALL',
    FETCH_FEED_CALL_BUNK_SCORE_LIST: 'FETCH_FEED_CALL_BUNK_SCORE_LIST',
    FETCH_FEED_CALL_SHIPPING_LIST: 'FETCH_FEED_CALL_SHIPPING_LIST',
    SET_FEED_CALL_TABLE: 'SET_FEED_CALL_TABLE',
    SET_IS_ALL_FEED_CALL_LOADED: 'SET_IS_ALL_FEED_CALL_LOADED',
    FETCH_FEED_CALL_GRAPH: 'FETCH_FEED_CALL_GRAPH',
    SET_FEED_CALL_TOTAL_CALL_ESTIMATED_HD_FIELD_NAME:
        'SET_FEED_CALL_TOTAL_CALL_ESTIMATED_HD_FIELD_NAME',
    SET_FEED_CALL_FILTER: 'SET_FEED_CALL_FILTER',
    SET_FEED_CALL_LOADING: 'SET_FEED_CALL_LOADING',
    SET_FEED_CALL_CAN_CALL_API: 'SET_FEED_CALL_CAN_CALL_API',
    FEED_CALL_SELECT_EST_HD_COUNT: 'FEED_CALL_SELECT_EST_HD_COUNT',
    DELETE_FEED_CALL: 'DELETE_FEED_CALL',
    FEED_CALL_EST_HEAD_COUNT: 'FEED_CALL_EST_HEAD_COUNT',
    FEED_CALL_TOTAL_CALL: 'FEED_CALL_TOTAL_CALL',
    SET_FEED_CALL_GRAPH_LIMIT: 'SET_FEED_CALL_GRAPH_LIMIT',
    FETCH_FEED_CALL_BUNK_SCORE_DETAILS: 'FETCH_FEED_CALL_BUNK_SCORE_DETAILS',
    SET_FEED_CALL_NO_OF_SHIPMENT: 'SET_FEED_CALL_NO_OF_SHIPMENT',
    SET_EXISTING_ESTIMATED_HEAD_COUNT: 'SET_EXISTING_ESTIMATED_HEAD_COUNT',
    SET_ESTIMATED_HEAD_COUNT_LOTS: 'SET_ESTIMATED_HEAD_COUNT_LOTS',
    SET_ESTIMATED_HEAD_COUNT_MODAL_LOTS: 'SET_ESTIMATED_HEAD_COUNT_MODAL_LOTS',
    SET_INITIAL_EXISTING_ESTIMATED_HEAD_COUNT:
        'SET_INITIAL_EXISTING_ESTIMATED_HEAD_COUNT',
    SET_LOT_EST_HEAD_COUNT_ERROR: 'SET_LOT_EST_HEAD_COUNT_ERROR',
    SET_EXISTING_ESTIMATED_HEAD_COUNT_ERROR:
        'SET_EXISTING_ESTIMATED_HEAD_COUNT_ERROR',
    SET_INITIAL_EXISTING_ESTIMATED_HEAD_COUNT_ERROR:
        'SET_INITIAL_EXISTING_ESTIMATED_HEAD_COUNT_ERROR',
    SET_IS_ESTIMATED_HEAD_COUNT_MODAL_OPEN:
        'SET_IS_ESTIMATED_HEAD_COUNT_MODAL_OPEN',
    RESET_EST_HEAD_COUNT: 'RESET_EST_HEAD_COUNT',
    SET_IS_ESTIMATED_HEAD_COUNT_FIELD: 'SET_IS_ESTIMATED_HEAD_COUNT_FIELD',
    ORIGINAL_FEED_CALL_ASSESSMENT_PAYLOAD:
        'ORIGINAL_FEED_CALL_ASSESSMENT_PAYLOAD',
    SET_EXISTING_ESTIMATED_HEAD_COUNT_CACHE:
        'SET_EXISTING_ESTIMATED_HEAD_COUNT_CACHE',
    SET_ESTIMATED_HEAD_COUNT_MODAL_LOTS_CACHE:
        'SET_ESTIMATED_HEAD_COUNT_MODAL_LOTS_CACHE',
    SET_CAN_SHOW_RATION_CONFIRMATION_MODAL:
        'SET_CAN_SHOW_RATION_CONFIRMATION_MODAL',
    SET_CAN_SHOW_RATION_CHANGE_REMINDER_MODAL:
        'SET_CAN_SHOW_RATION_CHANGE_REMINDER_MODAL',
    SET_FEEDCALL_ASSESSMENT_LOADER: 'SET_FEEDCALL_ASSESSMENT_LOADER',
    SET_SAVE_PREV_NEXT_BTN_DISABLED: 'SET_SAVE_PREV_NEXT_BTN_DISABLED',
    SET_FEED_CALL_RATIONS: 'SET_FEED_CALL_RATIONS',
    SET_IS_RATION_FILTER_CHANGED: 'SET_IS_RATION_FILTER_CHANGED',
    SET_IS_SCHEDULED_RATION_CHANGED: 'SET_IS_SCHEDULED_RATION_CHANGED',
    SET_CAN_SHOW_AF_CHANGE_CONFIRMATION_MODAL:
        'SET_CAN_SHOW_AF_CHANGE_CONFIRMATION_MODAL',
    SET_IS_FEED_CALL_CREATED: 'SET_IS_FEED_CALL_CREATED',
    SET_IS_SAVE_AND_NEXT_BTN_CLICKED: 'SET_IS_SAVE_AND_NEXT_BTN_CLICKED',
    SET_IS_EST_HD_COUNT_AVAILABLE: 'SET_IS_EST_HD_COUNT_AVAILABLE',
    SET_SHOW_PEN_RATION_TEMPLATE_MODAL: 'SET_SHOW_PEN_RATION_TEMPLATE_MODAL',
    SET_PEN_RATION_TEMPLATE_MODAL_CLOSED:
        'SET_PEN_RATION_TEMPLATE_MODAL_CLOSED',
    SET_OLD_FEED_CALL_TOTAL_CALL: 'SET_OLD_FEED_CALL_TOTAL_CALL',
    SET_OLD_DM_CHANGE_PER_HD_VALUE: 'SET_OLD_DM_CHANGE_PER_HD_VALUE',
    SET_IS_SHOW_FULL_PAGE_LOADER: 'SET_IS_SHOW_FULL_PAGE_LOADER',
    SET_FEED_CALL_LOT_INFORMATION: 'SET_FEED_CALL_LOT_INFORMATION',
    SET_FEED_CALL_RATION_INFORMATION: 'SET_FEED_CALL_RATION_INFORMATION',
    SET_IS_DISABLED_FIELDS_BUTTONS: 'SET_IS_DISABLED_FIELDS_BUTTONS',
    SET_IS_PEN_RATION_SCHEDULE_LOADING: 'SET_IS_PEN_RATION_SCHEDULE_LOADING',
};

export const FeedRouteCreationActionTypes = {
    SET_FEED_ROUTE_CREATION_SUBROUTES: 'SET_FEED_ROUTE_CREATION_SUBROUTES',
    STATIC_DELETE_SUB_ROUTES: 'STATIC_DELETE_SUB_ROUTES',
    FETCH_FEED_ROUTE_CREATION_SUBROUTES_RATIONS:
        'FETCH_FEED_ROUTE_CREATION_SUBROUTES_RATIONS',
    FETCH_FEED_ROUTE_CREATION_SUBROUTES_PENS:
        'FETCH_FEED_ROUTE_CREATION_SUBROUTES_PENS',
    SET_FEED_ROUTE_CREATION_RESET_STATE: 'SET_FEED_ROUTE_CREATION_RESET_STATE',
    SET_FEED_ROUTE_CREATION_DEFAULT_STATE:
        'SET_FEED_ROUTE_CREATION_DEFAULT_STATE',
};

export const PenRationScheduleActionTypes = {
    SET_SCHEDULE_TYPE: 'SET_SCHEDULE_TYPE',
    SET_PEN_RATION_SCHEDULE_BASIC_DETAIL:
        'SET_PEN_RATION_SCHEDULE_BASIC_DETAIL',
    SET_PEN_RATION_SCHEDULE_BASIC_DETAIL_ERROR:
        'SET_PEN_RATION_SCHEDULE_BASIC_DETAIL_ERROR',
    SET_PEN_RATION_SCHEDULE_RATIONS: 'SET_PEN_RATION_SCHEDULE_RATIONS',
    SET_PEN_RATION_SCHEDULE: 'SET_PEN_RATION_SCHEDULE',
    SET_PEN_RATION_SCHEDULES: 'SET_PEN_RATION_SCHEDULES',
    SET_DELETED_PEN_RATION_SCHEDULES: 'SET_DELETED_PEN_RATION_SCHEDULES',
    SET_PEN_RATION_SCHEDULE_INDEX: 'SET_PEN_RATION_SCHEDULE_INDEX',
    SET_CAN_CALL_API: 'SET_CAN_CALL_API',
    SET_PEN_RATION_SCHEDULE_LOADING: 'SET_LOADING',
    RESET_PEN_RATION_SCHEDULE: 'RESET_PEN_RATION_SCHEDULE',
    SET_PEN_INFO: 'SET_PEN_INFO',
    PEN_RATION_SELECTED_PEN_ID: 'PEN_RATION_SELECTED_PEN_ID',
    SET_PEN_RATION_SCHEDULE_ERRORS: 'SET_PEN_RATION_SCHEDULE_ERRORS',
    SET_IS_PEN_FOR_SELECTED_FEEDLOT: 'SET_IS_PEN_FOR_SELECTED_FEEDLOT',
    SET_VARIABLE_SPLIT_RATION_SCHEDULE: 'SET_VARIABLE_SPLIT_RATION_SCHEDULE',
    SET_VARIABLE_SPLIT_RATION_SCHEDULES: 'SET_VARIABLE_SPLIT_RATION_SCHEDULES',
    SET_DELETED_VARIABLE_SPLIT_RATION_SCHEDULES:
        'SET_DELETED_VARIABLE_SPLIT_RATION_SCHEDULES',
    SET_VARIABLE_SPLIT_SCHEDULE_ERROR: 'SET_VARIABLE_SPLIT_SCHEDULE_ERROR',
    SET_VARIABLE_SPLIT_SCHEDULES_ERROR: 'SET_VARIABLE_SPLIT_SCHEDULES_ERROR',
    SET_SELECTED_SCHEDULE_TYPE: 'SET_SELECTED_SCHEDULE_TYPE',
    SET_IS_PEN_RATION_SCHEDULE_EDITED: 'SET_IS_PEN_RATION_SCHEDULE_EDITED',
    SET_IS_NEW_PEN_RATION_SCHEDULE: 'SET_IS_NEW_PEN_RATION_SCHEDULE',
};

export const FeedRationOrderActionTypes = {
    SET_FEED_RATION_ORDER_RATIONS: 'SET_FEED_RATION_ORDER_RATIONS',
    SET_FEED_RATION_ORDER_MIXERS: 'SET_FEED_RATION_ORDER_MIXERS',
    SET_FEED_RATION_ORDER_CAN_RESET_TIME:
        'SET_FEED_RATION_ORDER_CAN_RESET_TIME',
    SET_FEED_RATION_ORDER_LOADING: 'SET_FEED_RATION_ORDER_LOADING',
    SET_FEED_RATION_ORDER_RESET_STATE: 'SET_FEED_RATION_ORDER_RESET_STATE',
};

export const MedicalItemsActionTypes = {
    SET_NEW_MEDICAL_ITEM: 'SET_NEW_MEDICAL_ITEM',
    SET_NEW_MEDICAL_ITEM_ERRORS: 'SET_NEW_MEDICAL_ITEM_ERRORS',
    SET_MEDICAL_ITEMS: 'SET_MEDICAL_ITEMS',
    SET_DROPDOWN_MEDICAL_ITEMS: 'SET_DROPDOWN_MEDICAL_ITEMS',
    SET_EARLIEST_GENERATED_INVOICE_CUTOFF_DATE:
        'SET_EARLIEST_GENERATED_INVOICE_CUTOFF_DATE',
    SET_MEDICAL_ITEMS_FILTER: 'SET_MEDICAL_ITEMS_FILTER',
    SET_MEDICAL_ITEMS_TABLE_LOADING: 'SET_MEDICAL_ITEMS_TABLE_LOADING',
    SET_MEDICAL_ITEMS_SUBMIT_PENDING: 'SET_MEDICAL_ITEMS_SUBMIT_PENDING',
};

export const MedicalInventoryActionTypes = {
    SET_MEDICAL_INVENTORY: 'SET_MEDICAL_INVENTORY',
    APPEND_MEDICAL_INVENTORY: 'APPEND_MEDICAL_INVENTORY',
    SET_ADJUSTMENT_INVENTORY: 'SET_ADJUSTMENT_INVENTORY',
    SET_COMPLETE_ADJUSTMENT_INVENTORY: 'SET_COMPLETE_ADJUSTMENT_INVENTORY',
    APPEND_ADJUSTMENT_INVENTORY: 'APPEND_ADJUSTMENT_INVENTORY',
    ADD_MEDICAL_INVENTORY: 'ADD_MEDICAL_INVENTORY',
    SET_MEDICAL_INVENTORY_FILTER: 'SET_MEDICAL_INVENTORY_FILTER',
    SET_INVENTORY_LOTS: 'SET_INVENTORY_LOTS',
    SET_MEDICAL_LOTS: 'SET_MEDICAL_LOTS',
    SET_CONTAINER_SIZES: 'SET_CONTAINER_SIZES',
    SET_ADJUSTMENT_HISTORY_FILTER: 'SET_ADJUSTMENT_HISTORY_FILTER',
    SET_MEDICAL_INVENTORY_LOADING: 'SET_MEDICAL_INVENTORY_LOADING',
    UPDATE_SUBSEQUENT_LOT_CHANGE_AMOUNT: 'UPDATE_SUBSEQUENT_LOT_CHANGE_AMOUNT',
    UPDATE_ALL_SUBSEQUENT_LOT_CHANGE_AMOUNTS:
        'UPDATE_ALL_SUBSEQUENT_LOT_CHANGE_AMOUNTS',
    SET_MEDICAL_INVENTORY_SUBMIT_PENDING:
        'SET_MEDICAL_INVENTORY_SUBMIT_PENDING',
};

export const TreatmentProtocolActionTypes = {
    FETCH_TREATMENT_PROTOCOLS: 'FETCH_TREATMENT_PROTOCOLS',
    APPEND_TREATMENT_PROTOCOLS: 'APPEND_TREATMENT_PROTOCOLS',
    SET_TREATMENT_PROTOCOLS: 'SET_TREATMENT_PROTOCOLS',
    SET_NEW_TREATMENT_PROTOCOL: 'SET_NEW_TREATMENT_PROTOCOL',
    POST_NEW_TREATMENT_PROTOCOL: 'POST_NEW_TREATMENT_PROTOCOL',
    SET_TREATMENT_PROTOCOL_MEDICINES: 'SET_TREATMENT_PROTOCOL_MEDICINES',
    SET_TREATMENT_PROTOCOLS_FILTER: 'SET_TREATMENT_PROTOCOLS_FILTER',
    SET_TREATMENT_FILTER: 'SET_TREATMENT_FILTER',
    SET_TREATMENT_PROTOCOL_SUBMIT_PENDING:
        'SET_TREATMENT_PROTOCOL_SUBMIT_PENDING',
};

export const DiagnosisActionTypes = {
    SET_DIAGNOSIS: 'SET_DIAGNOSIS',
    SET_DIAGNOSES: 'SET_DIAGNOSES',
    POST_DIAGNOSIS: 'POST_DIAGNOSIS',
    FETCH_DIAGNOSIS: 'FETCH_DIAGNOSIS',
};

export const FeedStatusActionTypes = {
    SET_LOAD_INFORMATION_DETAIL: 'SET_LOAD_INFORMATION_DETAIL',
    SET_PEN_INFORMATION_DETAIL: 'SET_PEN_INFORMATION_DETAIL',
    SET_FEED_STATUS_LOADING: 'SET_FEED_STATUS_LOADING',
    SET_FEED_STATUS_CAN_CALL_API: 'SET_FEED_STATUS_CAN_CALL_API',
    SET_FEED_STATUS_LOADED_COMMODITIES: 'SET_FEED_STATUS_LOADED_COMMODITIES',
    SET_FEED_STATUS_FILTER: 'SET_FEED_STATUS_FILTER',
    SET_RATIONS: 'SET_RATIONS',
    SET_DRIVERS: 'SET_DRIVERS',
    SET_FEEDINGS: 'SET_FEEDINGS',
    SET_PENS: 'SET_PENS',
    SET_SELECTED_FEED_TASK_ID: 'SET_SELECTED_FEED_TASK_ID',
    SET_FEED_STATUS_REFRESH_TIME: 'SET_FEED_STATUS_REFRESH_TIME',
};

export const ScaleTicketActionTypes = {
    SET_SCALE_TICKET_LOADING: 'SET_SCALE_TICKET_LOADING',
    SET_THE_SCALE_TICKET_TYPE: 'SET_THE_SCALE_TICKET_TYPE',
    SET_SCALE_TICKET_CAN_CALL_API: 'SET_SCALE_TICKET_CAN_CALL_API',
    SET_SCALE_TICKET_FILTER: 'SET_INBOUND_SCALE_TICKET_FILTER',
    SET_SCALE_TICKETS: 'SET_SCALE_TICKETS',
    SET_COMMODITIES: 'SET COMMODITIES',
    SET_VENDOR: 'SET VENDOR',
    SET_CONTRACT: 'SET_CONTRACT',
    SET_COMPLETE_CONTRACTS: 'SET_COMPLETE_CONTRACTS',
    SET_TRUCKING_COMPANIES: 'SET_TRUCKING_COMPANIES',
    SET_CREATE_TICKET_DETAIL: 'SET_CREATE_TICKET_DETAIL',
    SET_EDIT_TICKET_DETAIL: 'SET_EDIT_TICKET_DETAIL',
    SET_EDIT_TICKET_DETAIL_ERROR: 'SET_EDIT_TICKET_DETAIL_ERROR',
    SET_CREATE_TICKET_DETAIL_ERROR: 'SET_CREATE_TICKET_DETAIL_ERROR',
    SET_VENDOR_MODAL_DETAILS: 'SET_VENDOR_MODAL_DETAILS',
    SET_VENDOR_MODAL_DETAILS_ERROR: 'SET_VENDOR_MODAL_DETAILS_ERROR',
    SET_TRUCKING_COMPANY_MODAL_DETAILS: 'SET_TRUCKING_COMPANY_MODAL_DETAILS',
    SET_TRUCKING_COMPANY_MODAL_DETAILS_ERROR:
        'SET_TRUCKING_COMPANY_MODAL_DETAILS_ERROR',
    SET_CONTRACT_MODAL_DETAILS: 'SET_CONTRACT_MODAL_DETAILS',
    SET_CONTRACT_MODAL_DETAILS_ERROR: 'SET_CONTRACT_MODAL_DETAILS_ERROR',
    SET_SELECTED_SCALE_TICKET_TAB: 'SET_SELECTED_SCALE_TICKET_TAB',
    UPDATE_EDIT_TICKET_DETAIL: 'UPDATE_EDIT_TICKET_DETAIL',
    SET_SCALE_TICKETS_EXCEL_DATA: 'SET_SCALE_TICKETS_EXCEL_DATA',
    SET_SCALE_TICKET_SUBMIT_PENDING: 'SET_SCALE_TICKET_SUBMIT_PENDING',
};

export const HotListActionTypes = {
    SET_HOTLIST_LOADING: 'SET_HOTLIST_LOADING',
    SET_HOTLIST_DETAIL: 'SET_HOTLIST_DETAIL',
    SET_COMPLETE_HOTLIST: 'SET_COMPLETE_HOTLIST',
    SET_HOTLIST_FILTER_ITEMS: 'SET_HOTLIST_FILTER_ITEMS',
    SET_HOTLIST_CAN_CALL_API: 'SET_HOTLIST_CAN_CALL_API',
    SET_HOTLIST_FILTER: 'SET_HOTLIST_FILTER',
    SET_LOTS_FILTER: 'SET_LOTS_FILTER',
};

export const CommodityInventoryReportActionTypes = {
    SET_COMMODITY_REPORT: 'SET_COMMODITY_REPORT',
    SET_COMMODITY_REPORT_LOADING: 'SET_COMMODITY_REPORT_LOADING',
};

export const FeedReportsActionTypes = {
    SET_SEVEN_DAY_FEED_REPORT: 'SET_SEVEN_DAY_FEED_REPORT',
    SET_MANUAL_FEEDING_SHEET_REPORT: 'SET_MANUAL_FEEDING_SHEET_REPORT',
    SET_AVERAGE_AS_FED_REPORT: 'SET_AVERAGE_AS_FED_REPORT',
    SET_SCHEDULE_RATION_CHANGE_REPORT: 'SET_SCHEDULE_RATION_CHANGE_REPORT',
};

export const HospitalActionTypes = {
    SET_HOSPITAL_LOADING: 'SET_HOSPITAL_LOADING',
    SET_HOSPITAL_ANIMALS_LIST: 'SET_HOSPITAL_ANIMALS_LIST',
    SET_HOSPITAL_ANIMALS_REPORTS: 'SET_HOSPITAL_ANIMALS_REPORTS',
    SET_HOSPITAL_PENS: 'SET_HOSPITAL_PENS',
    SET_HOSPITAL_FILTER: 'SET_HOSPITAL_FILTER',
    SET_HOSPITAL_ANIMAL_ADMIN_HISTORY: 'SET_HOSPITAL_ANIMAL_ADMIN_HISTORY',
    SET_HOSPITAL_LOADING_HISTORY: 'SET_HOSPITAL_LOADING_HISTORY',
    SET_MOVE_PENS: 'SET_MOVE_PENS',
};

export const CalendarActionTypes = {
    SET_CALENDAR_EVENTS: 'SET_CALENDAR_EVENTS',
};

export const PenActionTypes = {
    SET_PEN_FILTER: 'SET_PEN_FILTER',
};

export const ActionTypes = {
    ...CreateBuyPoActionTypes,
    ...CreateReceiveCattleTypes,
    ...ShipCattleActionTypes,
    ...CreateRationTypes,
    ...PenRationTemplatesActionTypes,
    ...FeedCallActionTypes,
    ...PenRationScheduleActionTypes,
    ...FeedCallRoutesActionTypes,
    ...MedicalItemsActionTypes,
    ...MedicalInventoryActionTypes,
    ...TreatmentProtocolActionTypes,
    ...DiagnosisActionTypes,
    ...ScaleTicketActionTypes,
    ...HotListActionTypes,
    ...FeedStatusActionTypes,
    ...CommodityInventoryReportActionTypes,
    ...FeedReportsActionTypes,
    ...HospitalActionTypes,
    ...CalendarActionTypes,
    ...PenActionTypes,
};
