import './nav/nav.scss';
import { useLocation } from 'react-router-dom';
import Constants from '../utils/Constants';
import useAuthContext from '../apps/common/modules/auth/hooks/useAuthContext';
import BreadCrumbs from '../utils/BreadCrumbs';
import OwnerDropdown from './nav/OwnerDropdown';
import FeedlotDropdown from './nav/FeedlotDropdown';
import UserProfileDropdown from './nav/UserProfileDropdown';
import MobileNav from './nav/MobileNav';
import { INav } from '../utils/Interface/NavInterface';
import LanguageDropdown from './nav/LanguageDropdown';
import NetworkDisplay from './nav/NetworkDisplay';
import { Button } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../Redux/Store';
import { commonActions } from '../Redux/Reducer/common';
import SupportModal from './nav/SupportModal';
import {
    ApplicationTabNames,
    sideNavTabs,
} from '../utils/Interface/CommonInterface';
import { feedlotManagerFeatures } from '../apps/feedlotManager/featureFlags/feedlotManagerFeatures';
import { cattlePO } from '../apps/shippingTracker/featureFlags/cattlePOFeatures';
import { commonFeatures } from '../apps/common/featureFlags/commonFeatures';

const { isCA13765On_TokenManagementRework } = commonFeatures;
const { isCA12972On_RefreshFeedStatus } = feedlotManagerFeatures;

const Nav = ({ sideNavTab }: INav) => {
    const location = useLocation();
    const { isBP1185_SupportButton } = cattlePO;
    let headerPath = location.pathname.split('/')[1].toLowerCase();
    const dispatch = useAppDispatch();
    const { isAuthenticated } = useAuthContext();
    const { currentSelectedTab } = useAppSelector(state => state.common);
    const shouldShowButton = isCA13765On_TokenManagementRework
        ? currentSelectedTab === sideNavTabs.Fm ||
          (currentSelectedTab === sideNavTabs.Financial &&
              isBP1185_SupportButton) ||
          (currentSelectedTab === sideNavTabs.BuyPo && isBP1185_SupportButton)
        : sideNavTab === 'fm' ||
          (sideNavTab === 'financial' && isBP1185_SupportButton) ||
          (sideNavTab === 'buyPo' && isBP1185_SupportButton);

    if (['buypos', 'shippos'].includes(headerPath)) {
        headerPath = location.pathname.split('/')[2]?.toLowerCase();
    }

    const handleClick = () => {
        dispatch(commonActions.setShowSupportModal(true));
    };

    return !isAuthenticated() ? null : (
        <>
            <div
                id="sidebar"
                className="top-navigation-bar d-flex justify-content-between align-items-center"
            >
                <div className="d-flex">
                    {headerPath !== 'feedcall' &&
                    `/${headerPath}` !== Constants.Routes.NEWFEEDCALLV1DOT1 &&
                    headerPath !== 'yardlayout' ? (
                        <h6 className="block-heading m-0">
                            <BreadCrumbs />
                        </h6>
                    ) : (
                        <h5 className="block-heading m-0">
                            {Constants.headerConstants[headerPath] ?? null}
                        </h5>
                    )}
                </div>
                {!isCA12972On_RefreshFeedStatus && (
                    <div className="d-flex">
                        <NetworkDisplay />
                    </div>
                )}
                <div className="d-flex align-items-center">
                    {isCA12972On_RefreshFeedStatus && <NetworkDisplay />}
                    {shouldShowButton && (
                        <Button className="support-modal" onClick={handleClick}>
                            {Constants.supportModal.buttonName}
                        </Button>
                    )}
                    {(
                        isCA13765On_TokenManagementRework
                            ? currentSelectedTab === sideNavTabs.Fm
                            : sideNavTab === 'fm'
                    ) ? (
                        <>
                            <LanguageDropdown />
                            <FeedlotDropdown />
                        </>
                    ) : (isCA13765On_TokenManagementRework
                          ? currentSelectedTab === sideNavTabs.Financial
                          : sideNavTab === 'financial') &&
                      Constants.showOwnerDropdown.includes(
                          Constants.headerConstants[headerPath],
                      ) ? (
                        <OwnerDropdown />
                    ) : null}

                    <UserProfileDropdown />
                </div>
            </div>
            <SupportModal />
            {(isCA13765On_TokenManagementRework
                ? currentSelectedTab === sideNavTabs.Fm
                : currentSelectedTab.toString().toLowerCase() !==
                  ApplicationTabNames.FeedlotManager) && <MobileNav />}
        </>
    );
};

export default Nav;
