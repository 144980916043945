interface GenericMedicalItem {
    medicalItemId: string | number;
    medicalLot?: GenericMedicalLot[];
    medicalLotId?: string | number;
}

interface GenericMedicalLot {
    medicalLotId?: string;
    lotAmount?: number;
}

export const medicalLotHasInventory = (
    medicalLot: GenericMedicalLot,
): boolean => {
    return medicalLot.lotAmount && medicalLot.lotAmount > 0 ? true : false;
};

export const medicalItemHasInventory = <T extends GenericMedicalItem>(
    medicalItem?: T,
): boolean => {
    return medicalItem?.medicalLot?.some(medicalLotHasInventory) ?? false;
};

const medicalLotsSelectedForSameMedicalItem = (
    medicalItemCharges: GenericMedicalItem[],
    medicalItemId: number,
    indexForThisCharge: number,
) => {
    return medicalItemCharges
        .filter(
            (item, medicalItemChargeIndex) =>
                item.medicalLotId !== undefined &&
                medicalItemChargeIndex !== indexForThisCharge &&
                item.medicalItemId === medicalItemId,
        )
        .map(item => item.medicalLotId);
};

export const medicalLotAlreadyInUse = <
    T extends GenericMedicalLot,
    U extends GenericMedicalItem,
>(
    medicalItemCharges: U[],
    medicalItemId: number,
    medicalLot: T,
    indexForThisCharge: number,
): boolean => {
    if (medicalLot.medicalLotId) {
        return medicalLotsSelectedForSameMedicalItem(
            medicalItemCharges,
            medicalItemId,
            indexForThisCharge,
        ).includes(medicalLot.medicalLotId);
    } else {
        return false;
    }
};
