import { feedlotManagerFeatures } from '../../../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';
import { deepClone } from '../../../../helpers';
import { NumericalOperations } from '../../../../NumericalOperationPackage/numericalOperationsInterfaces';
import { CommodityUnit } from '../interfaces/ManageCommoditiesInterface';
import Big from 'big.js';

const { isCA13790On_AbstractBigJs } = feedlotManagerFeatures;
interface IBaseCommodityItem {
    unit?: CommodityUnit;
}

export const convertCommodityItem = <
    T extends IBaseCommodityItem,
    K extends keyof T & string,
>(
    commodityItem: T,
    fieldsToConvert: K[],
    currentWeightUnit: CommodityUnit,
): T => {
    const commodityItemCopy = deepClone(commodityItem);

    if (!commodityItemCopy.unit) {
        commodityItemCopy.unit = CommodityUnit.Lbs;
    }

    if (commodityItemCopy.unit !== currentWeightUnit) {
        fieldsToConvert.forEach(field => {
            const fieldValue = commodityItemCopy[field];
            if (!+fieldValue) return;
            if (
                typeof fieldValue !== 'string' &&
                typeof fieldValue !== 'number'
            )
                return;
            let convertedValue: number | Big;
            if (isCA13790On_AbstractBigJs) {
                const factor =
                    currentWeightUnit === CommodityUnit.Tons ? 2000 : 1 / 2000;

                convertedValue =
                    field === 'price'
                        ? NumericalOperations().multiply(+fieldValue, +factor)
                        : NumericalOperations().divide(+fieldValue, +factor);
            } else {
                const normalizedFieldValue = Big(fieldValue);
                const factor = Big(
                    currentWeightUnit === CommodityUnit.Tons ? 2000 : 1 / 2000,
                );

                convertedValue =
                    field === 'price'
                        ? normalizedFieldValue.times(factor)
                        : normalizedFieldValue.div(factor);
            }

            commodityItemCopy[field] = +convertedValue as T[K];
        });

        commodityItemCopy.unit = currentWeightUnit;
    }
    return commodityItemCopy;
};

export const convertCommoditiesItem = <
    T extends IBaseCommodityItem,
    K extends keyof T & string,
>(
    commoditiesItem: T[],
    fieldsToConvert: K[],
    currentWeightUnit: CommodityUnit,
): T[] => {
    return commoditiesItem.map((item: T) =>
        convertCommodityItem(item, fieldsToConvert, currentWeightUnit),
    );
};
