import {
    IAttachment,
    IAttachmentResponse,
    IBuyPoFee,
    IBuyPoFeeResponse,
    IBuyPoFilter,
    IBuyPOList,
    IBuyPoOrderResponse,
    ICheckFileTypeExtension,
    IDeleteBuyPOIds,
    IFetchBuyer,
    IDestinationDetail,
    IFetchBuyPoDestinations,
    IFetchBuyPoLocation,
    IFetchDestinationResponse,
    IFetchLocationResponse,
    IFetchTruckingCompanies,
    IFetchUpsertGlobalInfoPayload,
    IGlobalInfo,
    IGlobalInfoResponse,
    IHandleBuyPoStepVisibility,
    ILocationDetail,
    INotificationModalApiRequest,
    IOrder,
    IOrderPayload,
    IOrdersAndFeeTotal,
    ISetBuyPoCompanyOwners,
    IUpdateBuyPoPayload,
    IUpdateBuyPoStatePayload,
    IUpdateFeesPayload,
    IFetchKillGroup,
    IBuyerDetail,
    IFetchBuyerResponse,
    IKillGroupDetail,
    IFetchKillGroupResponse,
    IInactiveBuyPoDestination,
    IInactiveBuyPoLocation,
    IBuyPoTruckingCompanyDetail,
    IBuyPoKillGroupDetail,
    IEditDestinationDetail,
    IEditLocationDetail,
    IFetchTruckingCompanyResponse,
    IPayeeDetail,
    IFetchPayeeResponse,
    IFetchPayee,
    IChangeOffsetAccordingPaidStatus,
    ICsvData,
    IPaymentInfo,
    IFetchColorResponse,
    IColorDetail,
    ISetColor,
    IFetchSexResponse,
    ISexDetail,
    ISetSex,
    ISetBreeds,
    IFetchBreedResponse,
    IBreedDetail,
    IFetchSlideUnitResponse,
    ISlideUnitDetail,
    ISetSlideUnit,
    IBuyPODetails,
    IFetchOwnerResponse,
    IOwnerDetail,
} from '../interface/BuyPoInterface';
import { CREATE_BUY_PO_INITIAL_STATE } from '../constants/buyPoInitialState';
import { CreateBuyPoActionTypes } from '../../../../../Redux/Actions';
import { AppDispatch, AppThunkDispatch } from '../../../../../Redux/Store';
import { apiCall, apiCall_v2 } from '../../../../../Services/AxiosService';
import Constants from '../../../../../utils/Constants';
import { formatDate, currentDate } from '../../../../../helpers/dateTime';
import { deepClone, replaceAmpersandSymbol } from '../../../../../helpers';
import {
    buyPoExcelConstants,
    BuyPoFeesConstants,
    BuyPOListConstants,
} from '../constants/BuyPoConstants';
import { getToastContent } from '../../../../../utils/ToastContent';
import {
    calculateAvgGrossWeightPerHead,
    calculateGrandTotal,
    calculateOrdersORShipmentsAndFeeTotal,
    isAttachFileFormDisabled,
} from '../../../../../helpers/CreateBuyPoCommonFunc';
import { IGenericSetState } from '../../../../../utils/Interface/CommonInterface';
import dayjs from '../../../../../utils/dayjs';
import { toastMessage } from '../../../../../Redux/Reducer/common';
import { cattlePO } from '../../../featureFlags/cattlePOFeatures';

const { isBP1246_buypoFilterQuerySync } = cattlePO;
export const setBuyPoGlobalInfo = (
    buyPoGlobalInfo = { ...CREATE_BUY_PO_INITIAL_STATE.buyPoGlobalInfo },
) => {
    return {
        type: CreateBuyPoActionTypes.SET_GLOBAL_INFO_BUYPO,
        payload: buyPoGlobalInfo,
    };
};

export const updateBuyPoGlobalInfoId = (globalInfoId: number) => {
    return {
        type: CreateBuyPoActionTypes.UPDATE_GLOBAL_INFO_BUYPO_ID,
        payload: globalInfoId,
    };
};

export const setBuyPoGlobalInfoError = (
    buyPoGlobalInfoError = {
        ...CREATE_BUY_PO_INITIAL_STATE.buyPoGlobalInfoError,
    },
) => {
    return {
        type: CreateBuyPoActionTypes.SET_GLOBAL_INFO_ERROR_BUYPO,
        payload: buyPoGlobalInfoError,
    };
};

export const setBuyPoPickupDateRange = (
    dateRange = {
        from: '',
        to: '',
    },
) => {
    return {
        type: CreateBuyPoActionTypes.SET_DATE_RANGE_BUYPO,
        payload: dateRange,
    };
};

export const setBuyPoViewSteps = (
    buyPOViewSteps = { ...CREATE_BUY_PO_INITIAL_STATE.buyPOViewSteps },
) => {
    return {
        type: CreateBuyPoActionTypes.SET_COMPONENTS_STEP_STATE_BUYPO,
        payload: buyPOViewSteps,
    };
};

export const setBuyPoOrders = (
    orders = [{ ...CREATE_BUY_PO_INITIAL_STATE.ORDERS_INITIAL }],
) => {
    return {
        type: CreateBuyPoActionTypes.SET_ORDERS_STATE_BUYPO,
        payload: orders,
    };
};

export const setBuyPoOrdersAndFeesTotal = (
    ordersAndFeeTotal = { ...CREATE_BUY_PO_INITIAL_STATE.ordersAndFeeTotal },
) => {
    return {
        type: CreateBuyPoActionTypes.SET_ORDERS_AND_FEE_TOTAL_STATE_BUYPO,
        payload: ordersAndFeeTotal,
    };
};

export const setBuyPoFees = (
    fees: IBuyPoFee[] = [{ ...BuyPoFeesConstants.initialState.initialFee }],
) => {
    return {
        type: CreateBuyPoActionTypes.SET_FEE_TYPES_STATE_BUYPO,
        payload: fees,
    };
};

export const setBuyPoLastModifiedDetail = (
    lastModifiedDate = CREATE_BUY_PO_INITIAL_STATE.lastModifiedDate,
    lastModifiedUser = CREATE_BUY_PO_INITIAL_STATE.lastModifiedUser,
) => {
    return {
        type: CreateBuyPoActionTypes.SET_LAST_MODIFIED_DETAILS_BUYPO,
        payload: { lastModifiedDate, lastModifiedUser },
    };
};

export const setBuyPoDestinationDetail = (
    selectedDestinationDetail = {
        ...CREATE_BUY_PO_INITIAL_STATE.selectedDestinationDetail,
    },
) => {
    return {
        type: CreateBuyPoActionTypes.SET_DESTINATION_DETAIL_BUY_PO,
        payload: selectedDestinationDetail,
    };
};

export const setBuyPoFileDetail = (buyPoFileDetail: {
    buyPoFileArray: IAttachment[];
    isAttachFileDisable?: boolean;
}) => {
    return {
        type: CreateBuyPoActionTypes.SET_BUY_PO_FILE_DETAIL,
        payload: buyPoFileDetail,
    };
};

export const setBuyPoLoading = (
    loading = CREATE_BUY_PO_INITIAL_STATE.loading,
) => {
    return {
        type: CreateBuyPoActionTypes.SET_BUY_PO_LOADING,
        payload: loading,
    };
};

export const setShouldAllowBuyPoPageChange = (
    shouldAllowPageChange = CREATE_BUY_PO_INITIAL_STATE.shouldAllowPageChange,
) => {
    return {
        type: CreateBuyPoActionTypes.SET_SHOULD_ALLOW_BUYPO_PAGE_CHANGE,
        payload: shouldAllowPageChange,
    };
};

export const updateLastModifiedDetail =
    (modifiedDateTime?: string, modifiedBy?: string) =>
    (dispatch: AppDispatch) => {
        const modifiedDate = modifiedDateTime
            ? formatDate(modifiedDateTime, 'MM-DD-YYYY hh:mm:ss a')
            : currentDate('MM-DD-YYYY hh:mm:ss a');
        const modifiedUser = modifiedBy ? modifiedBy : '';
        dispatch(setBuyPoLastModifiedDetail(modifiedDate, modifiedUser));
    };

export const deleteBuyPoOrder =
    (
        buyPoId: number | string,
        orderId: number | string,
        orderIndex: number,
        popOrder: (orderIndex: number) => void,
        modifiedBy: string,
    ) =>
    async (dispatch: AppDispatch) => {
        await apiCall(
            'delete',
            `${Constants.apiUrls.BUY_PO_INFO}/${buyPoId}${Constants.apiUrls.BUY_PO_ORDERS}/${orderId}`,
        );

        popOrder(orderIndex);
        dispatch(updateLastModifiedDetail(undefined, modifiedBy));
    };

export const createNewBuyer =
    (
        setLoading: IGenericSetState<boolean>,
        fetchBuyerPayload: () => IBuyerDetail,
        handleCreateBuyerResponse: (response: IFetchBuyerResponse) => void,
        handleActivateBuyer: () => Promise<void>,
    ) =>
    async (dispatch: AppDispatch) => {
        setLoading(true);
        const payload = fetchBuyerPayload();
        const response = await apiCall_v2({
            method: 'post',
            url: `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.BUYERS}`,
            payload,
            isResRequired: true,
        });
        setLoading(false);
        if (!response) return;
        const status = Number(response.status);

        if (status === Constants.responseCode.DUPLICATE) {
            dispatch(
                toastMessage(
                    getToastContent(
                        response.status,
                        '',
                        Constants.customMessage.BUYER_DUPLICATE,
                    ),
                ),
            );
        } else if (status === Constants.responseCode.CONFLICT) {
            handleActivateBuyer();
        } else if (status === Constants.responseCode.SUCCESS) {
            dispatch(toastMessage(getToastContent(response.status)));
            handleCreateBuyerResponse(response);
        }
    };

export const createNewOwner =
    (
        setLoading: IGenericSetState<boolean>,
        apiType: string,
        fetchOwnerPayload: () => IOwnerDetail,
        handleCreateOwnerResponse: (response: IFetchOwnerResponse) => void,
        handleActivateOwner: () => Promise<void>,
    ) =>
    async (dispatch: AppDispatch) => {
        setLoading(true);
        const payload = fetchOwnerPayload();
        const response = await apiCall_v2({
            method: 'post',
            url: `${apiType}${Constants.apiUrls.COMPANY_OWNERS}`,
            payload,
            isResRequired: true,
        });
        setLoading(false);
        if (!response) return;
        const status = Number(response.status);

        if (status === Constants.responseCode.DUPLICATE) {
            dispatch(
                toastMessage(
                    getToastContent(
                        response.status,
                        '',
                        Constants.customMessage.OWNER_DUPLICATE,
                    ),
                ),
            );
        } else if (status === Constants.responseCode.CONFLICT) {
            handleActivateOwner();
        } else if (status === Constants.responseCode.SUCCESS) {
            dispatch(toastMessage(getToastContent(response.status)));
            handleCreateOwnerResponse(response);
        }
    };

export const handleEditOwner =
    (
        setLoading: IGenericSetState<boolean>,
        handleCreateOwnerResponse: (buyerResponse: IFetchOwnerResponse) => void,
        fetchEditOwnerPayload: () => IOwnerDetail,
        handleActivateOwner: () => Promise<void>,
    ) =>
    async (dispatch: AppDispatch) => {
        setLoading(true);
        const payload = fetchEditOwnerPayload();
        const response = await apiCall_v2({
            method: 'put',
            url: `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.COMPANY_OWNERS}/${payload.id}`,
            payload,
            isResRequired: true,
        });
        setLoading(false);
        if (!response) return;
        const status = Number(response.status);

        if (status === Constants.responseCode.DUPLICATE) {
            dispatch(
                toastMessage(
                    getToastContent(
                        response.status,
                        '',
                        Constants.customMessage.OWNER_DUPLICATE,
                    ),
                ),
            );
        } else if (status === Constants.responseCode.CONFLICT) {
            handleActivateOwner();
        } else if (
            status >= Constants.responseCode.SUCCESS &&
            status < Constants.responseCode.MULTIPLE_CHOICES
        ) {
            dispatch(toastMessage(getToastContent(response.status)));
            handleCreateOwnerResponse(response);
        }
    };

export const createNewPayee =
    (
        setLoading: IGenericSetState<boolean>,
        fetchPayeePayload: () => IPayeeDetail,
        handleCreatePayeeResponse: (response: IFetchPayeeResponse) => void,
        handleActivatePayee: () => Promise<void>,
    ) =>
    async (dispatch: AppDispatch) => {
        setLoading(true);
        const payload = fetchPayeePayload();
        const response = await apiCall_v2({
            method: 'post',
            url: `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.PAYEE}`,
            payload,
            isResRequired: true,
        });
        setLoading(false);
        if (!response) return;
        const status = Number(response.status);

        if (status === Constants.responseCode.DUPLICATE) {
            dispatch(
                toastMessage(
                    getToastContent(
                        response.status,
                        '',
                        Constants.customMessage.PAYEE_DUPLICATE,
                    ),
                ),
            );
        } else if (status === Constants.responseCode.CONFLICT) {
            handleActivatePayee();
        } else if (status === Constants.responseCode.SUCCESS) {
            dispatch(toastMessage(getToastContent(response.status)));
            handleCreatePayeeResponse(response);
        }
    };

export const createNewColor =
    (
        setLoading: IGenericSetState<boolean>,
        fetchColorPayload: () => IColorDetail,
        handleCreateColorResponse: (response: IFetchColorResponse) => void,
        handleActivateColor: () => Promise<void>,
    ) =>
    async (dispatch: AppDispatch) => {
        setLoading(true);
        const payload = fetchColorPayload();
        const response = await apiCall_v2({
            method: 'post',
            url: `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.COLOR}`,
            payload,
            isResRequired: true,
        });

        setLoading(false);
        const status = Number(response.status);
        if (status === Constants.responseCode.DUPLICATE) {
            dispatch(
                toastMessage(
                    getToastContent(
                        response.status,
                        '',
                        Constants.customMessage.COLOR_DUPLICATE,
                    ),
                ),
            );
        } else if (status === Constants.responseCode.CONFLICT) {
            handleActivateColor();
        } else if (status === Constants.responseCode.SUCCESS) {
            dispatch(toastMessage(getToastContent(response.status)));
            handleCreateColorResponse(response ?? []);
        }
    };

export const createNewSex =
    (
        setLoading: IGenericSetState<boolean>,
        fetchSexPayload: () => ISexDetail,
        handleCreateSexResponse: (response: IFetchSexResponse) => void,
        handleActivateSex: () => Promise<void>,
    ) =>
    async (dispatch: AppDispatch) => {
        setLoading(true);
        const payload = fetchSexPayload();
        const response = await apiCall_v2({
            method: 'post',
            url: `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.SEX}`,
            payload,
            isResRequired: true,
        });

        setLoading(false);
        const status = Number(response.status);
        if (status === Constants.responseCode.DUPLICATE) {
            dispatch(
                toastMessage(
                    getToastContent(
                        response.status,
                        '',
                        Constants.customMessage.SEX_DUPLICATE,
                    ),
                ),
            );
        } else if (status === Constants.responseCode.CONFLICT) {
            handleActivateSex();
        } else if (status === Constants.responseCode.SUCCESS) {
            dispatch(toastMessage(getToastContent(response.status)));
            handleCreateSexResponse(response ?? []);
        }
    };

export const createNewBreed =
    (
        setLoading: IGenericSetState<boolean>,
        fetchBreedPayload: () => IBreedDetail,
        handleCreateBreedResponse: (response: IFetchBreedResponse) => void,
        handleActivateBreed: () => Promise<void>,
    ) =>
    async (dispatch: AppDispatch) => {
        setLoading(true);
        const payload = fetchBreedPayload();
        const response = await apiCall_v2({
            method: 'post',
            url: `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.BREED}`,
            payload,
            isResRequired: true,
        });

        setLoading(false);
        const status = Number(response.status);
        if (status === Constants.responseCode.DUPLICATE) {
            dispatch(
                toastMessage(
                    getToastContent(
                        response.status,
                        '',
                        Constants.customMessage.BREED_DUPLICATE,
                    ),
                ),
            );
        } else if (status === Constants.responseCode.CONFLICT) {
            handleActivateBreed();
        } else if (
            status >= Constants.responseCode.SUCCESS &&
            status < Constants.responseCode.MULTIPLE_CHOICES
        ) {
            dispatch(toastMessage(getToastContent(response.status)));
            handleCreateBreedResponse(response ?? []);
        }
    };

export const createNewSlideUnit =
    (
        setLoading: IGenericSetState<boolean>,
        fetchSlideUnitPayload: () => ISlideUnitDetail,
        handleCreateSlideUnitResponse: (
            response: IFetchSlideUnitResponse,
        ) => void,
    ) =>
    async () => {
        setLoading(true);
        const payload = fetchSlideUnitPayload();
        const response = await apiCall_v2({
            method: 'post',
            url: `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.SLIDE_UNIT}`,
            payload,
            isResRequired: true,
        });

        setLoading(false);
        handleCreateSlideUnitResponse(response ?? []);
    };

export const handleCreateNewTruckingCompany = async (
    setLoader: IGenericSetState<boolean>,
    fetchTruckingCompanyDetail: () => IBuyPoTruckingCompanyDetail,
    createTruckingCompanyResponseHandler: (
        response: IFetchTruckingCompanyResponse,
    ) => void,
) => {
    const payload = fetchTruckingCompanyDetail();
    const response = await apiCall(
        'post',
        Constants.apiUrls.TRUCKING_COMPANY,
        payload,
        true,
    );
    createTruckingCompanyResponseHandler(response);
    setLoader(false);
};

export const handleEditTruckingCompany = async (
    setLoader: IGenericSetState<boolean>,
    truckingCompanyDetail: IBuyPoTruckingCompanyDetail,
    createTruckingCompanyResponseHandler: (
        response: IFetchTruckingCompanyResponse,
    ) => void,
) => {
    const response = await apiCall_v2({
        method: 'put',
        url: `${Constants.apiUrls.TRUCKING_COMPANY}/${truckingCompanyDetail.companyId}`,
        payload: truckingCompanyDetail,
        isResRequired: true,
    });
    createTruckingCompanyResponseHandler(response);
    setLoader(false);
};

export const handleCreateNewKillGroup =
    (
        setLoading: IGenericSetState<boolean>,
        killGroupPayLoad: IKillGroupDetail,
        handleCreateKillGroupResponse: (
            response: IFetchKillGroupResponse,
        ) => void,
        handleActivateKillGroup: () => Promise<void>,
    ) =>
    async (dispatch: AppDispatch) => {
        setLoading(true);

        const response = await apiCall(
            'post',
            `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.KILL_GROUP}`,
            killGroupPayLoad,
            true,
        );
        setLoading(false);
        if (!response) return;
        const status = Number(response.status);

        if (status === Constants.responseCode.DUPLICATE) {
            dispatch(
                toastMessage(
                    getToastContent(
                        response.status,
                        '',
                        Constants.customMessage.KILL_GROUP_DUPLICATE,
                    ),
                ),
            );
        } else if (status === Constants.responseCode.CONFLICT) {
            handleActivateKillGroup();
        } else if (status === Constants.responseCode.SUCCESS) {
            dispatch(toastMessage(getToastContent(response.status)));
            handleCreateKillGroupResponse(response);
        }
    };

export const deleteBuyPo =
    (
        buyPoId: number | string,
        navigateToBuyPOList: () => void,
        payload?: INotificationModalApiRequest,
    ) =>
    async (dispatch: AppDispatch) => {
        dispatch(setBuyPoLoading(true));

        const response = await apiCall(
            'post',
            `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.DELETE}/${buyPoId}`,
            payload || {},
            true,
        );

        response.status = Number(response.status);

        if (
            response &&
            response.status >= Constants.responseCode.SUCCESS &&
            response.status < Constants.responseCode.MULTIPLE_CHOICES
        ) {
            dispatch(toastMessage(getToastContent(response.status, 'delete')));
        }

        dispatch(setBuyPoLoading(false));
        navigateToBuyPOList();
    };

export const popBuyPoDocument =
    (globalInfo: IGlobalInfo, attachmentId: number) =>
    (dispatch: AppDispatch) => {
        const buyPoFileArray = globalInfo.buyPoFileArray.filter(
            file => file.id !== attachmentId,
        );
        const isAttachFileDisable = isAttachFileFormDisabled(buyPoFileArray);

        dispatch(setBuyPoFileDetail({ buyPoFileArray, isAttachFileDisable }));
    };

export const deleteBuyPoDocument =
    (
        globalInfo: IGlobalInfo,
        attachmentId: number,
        setLoading: IGenericSetState<boolean>,
        modifiedBy: string,
    ) =>
    async (dispatch: AppDispatch) => {
        setLoading(true);

        await apiCall(
            'delete',
            `${Constants.apiUrls.BUY_PO_INFO}/${globalInfo.id}${Constants.apiUrls.ATTACHMENTS}/${attachmentId}`,
        );

        dispatch(popBuyPoDocument(globalInfo, attachmentId));
        dispatch(updateLastModifiedDetail(undefined, modifiedBy));
        setLoading(false);
    };

export const setIsCattlePaid = (isCattlePaid: boolean) => {
    return {
        type: CreateBuyPoActionTypes.SET_PAYMENT_INFO,
        payload: { isCattlePaid },
    };
};

export const setIsFreightPaid = (isFreightPaid: boolean) => {
    return {
        type: CreateBuyPoActionTypes.SET_PAYMENT_INFO,
        payload: { isFreightPaid },
    };
};

export const setIsCattleReceived = (isCattleReceived: boolean) => {
    return {
        type: CreateBuyPoActionTypes.SET_PAYMENT_INFO,
        payload: { isCattleReceived },
    };
};

export const setIsPaymentHold = (isPaymentHold: boolean) => {
    return {
        type: CreateBuyPoActionTypes.SET_PAYMENT_INFO,
        payload: { isPaymentHold },
    };
};

export const setPaymentInfo = (
    paymentInfo = { ...CREATE_BUY_PO_INITIAL_STATE.paymentInfo },
) => {
    return {
        type: CreateBuyPoActionTypes.SET_PAYMENT_INFO,
        payload: paymentInfo,
    };
};

export const updateBuyPO =
    (
        buyPOId: number | string,
        payload: IUpdateBuyPoPayload,
        method: 'patch' | 'put' = 'patch',
        changeOffsetAccordingPaidStatus?: IChangeOffsetAccordingPaidStatus,
    ) =>
    async (dispatch: AppDispatch) => {
        const response = await apiCall_v2({
            method,
            url: `${Constants.apiUrls.BUY_PO_INFO}/${buyPOId}`,
            payload: payload,
            isResRequired: true,
        });

        if (response && response.status && changeOffsetAccordingPaidStatus) {
            response.status = Number(response.status);

            if (
                response.status >= Constants.responseCode.SUCCESS &&
                response.status < Constants.responseCode.MULTIPLE_CHOICES
            ) {
                changeOffsetAccordingPaidStatus(
                    payload.cattlePaid || false,
                    payload.freightPaid || false,
                    payload.cattleReceived || false,
                    payload.holdPayment || false,
                    buyPOId,
                );
            }
            dispatch(toastMessage(getToastContent(response.status)));
        }
    };

export const updateBuyPoStatus =
    (
        buyPOId: number | string,
        payload: IUpdateBuyPoStatePayload,
        navigateToBuyPoList: () => void,
        buyPoGlobalInfo: IGlobalInfo,
        paymentInfo: IPaymentInfo,
    ) =>
    async (dispatch: AppDispatch) => {
        dispatch(setBuyPoLoading(true));
        const globalInfopayload = fetchUpsertGlobalInfoPayload({
            ...buyPoGlobalInfo,
        });

        const paymentInfoPayload = {
            cattlePaid: paymentInfo.isCattlePaid,
            cattleReceived: paymentInfo.isCattleReceived,
            freightPaid: paymentInfo.isFreightPaid,
            holdPayment: paymentInfo.isPaymentHold,
        };
        await dispatch(
            updateBuyPO(
                buyPOId,
                {
                    ...payload,
                    ...globalInfopayload,
                    ...paymentInfoPayload,
                },
                'put',
            ),
        );

        dispatch(setBuyPoLoading(false));
        navigateToBuyPoList();
    };

const fetchUpsertGlobalInfoPayload = (
    buyPoGlobalInfo: IGlobalInfo,
): IFetchUpsertGlobalInfoPayload => {
    const purchaseDate = buyPoGlobalInfo.buyPoPurchaseDate
        ? formatDate(dayjs(buyPoGlobalInfo.buyPoPurchaseDate), 'YYYY-MM-DD')
        : '';
    const pickupDate = buyPoGlobalInfo.buyPoPickupDate
        ? formatDate(dayjs(buyPoGlobalInfo.buyPoPickupDate), 'YYYY-MM-DD')
        : '';
    const pickUpFromDate = buyPoGlobalInfo.buyPoPickupFromDate
        ? formatDate(dayjs(buyPoGlobalInfo.buyPoPickupFromDate), 'YYYY-MM-DD')
        : '';
    const pickUpToDate = buyPoGlobalInfo.buyPoPickupToDate
        ? formatDate(dayjs(buyPoGlobalInfo.buyPoPickupToDate), 'YYYY-MM-DD')
        : '';

    return {
        purchaseDate,
        pickupDate,
        pickUpFromDate,
        pickUpToDate,
        buyLocationId: buyPoGlobalInfo.buyPoLocation,
        destinationId: buyPoGlobalInfo.buyPoDestination,
        truckingCompanyId: buyPoGlobalInfo.buyPoTruckingCompany,
        payeeId: buyPoGlobalInfo.buyPoPayee,
        invoiceOwnerId: buyPoGlobalInfo.buyPoInvoiceOwner,
        payee: buyPoGlobalInfo.payee,
        orderNotes: buyPoGlobalInfo.orderNotes,
        deadOnArrivalCount: buyPoGlobalInfo.DOA,
        buyerId: buyPoGlobalInfo.buyPoBuyer,
        buyer2Id: buyPoGlobalInfo.buyPoBuyer2,
        killGroupId: buyPoGlobalInfo.buyPoKillGroup,
        cutCount: buyPoGlobalInfo.cutCount,
    };
};

export const upsertGlobalInfo =
    (
        globalInfo: IGlobalInfo,
        setCanCallGlobalInfoApi: IGenericSetState<boolean>,
        modifiedBy,
    ) =>
    async (dispatch: AppDispatch) => {
        setCanCallGlobalInfoApi(false);
        const payload = fetchUpsertGlobalInfoPayload(globalInfo);

        if (globalInfo.id) {
            await dispatch(updateBuyPO(globalInfo.id, payload, 'put'));
        } else {
            const globalInfoId = await apiCall(
                'post',
                Constants.apiUrls.BUY_PO_INFO,
                payload,
            );
            if (typeof globalInfoId === 'number') {
                dispatch(updateBuyPoGlobalInfoId(globalInfoId));
            }
        }

        dispatch(updateLastModifiedDetail(undefined, modifiedBy));
    };

const fetchAttachmentPayload = (buyPoGlobalInfo: IGlobalInfo): FormData => {
    const formData = new FormData();
    const buypoGlobalInfoCopy = deepClone(buyPoGlobalInfo);
    buypoGlobalInfoCopy.files.forEach(file => {
        formData.append('files', file);
    });

    return formData;
};

const getFileExtensionFromBlob = (blob: Blob) => {
    const mimeType = blob.type;
    if (!mimeType) {
        return '';
    }
    const mimeTypeMap = {
        'image/jpeg': '.jpg',
        'image/png': '.png',
        'image/gif': '.gif',
        'image/webp': '.webp',
    };
    return mimeTypeMap[mimeType] ?? '';
};

export const getRichTextImagePayload = (blob: Blob): FormData => {
    const dateStamp = dayjs().valueOf();
    const fileName = dateStamp + getFileExtensionFromBlob(blob);
    const file = new File([blob], fileName, {
        lastModified: dateStamp,
    });
    const formData = new FormData();
    formData.append('files', file);
    return formData;
};

const formatBuyPoFileDetail = (
    buyPoId: number | string,
    attachmentsResponse: IAttachmentResponse[],
    checkFileTypeExtension: ICheckFileTypeExtension,
): IAttachment[] => {
    const attachments: IAttachment[] = attachmentsResponse.map(attachment => ({
        id: attachment.id,
        url: `${Constants.apiUrls.BUY_PO_INFO}/${buyPoId}${Constants.apiUrls.ATTACHMENTS}/${attachment.id}/${attachment.accessToken}`,
        file_name: attachment.name,
        fileTypeIcon: checkFileTypeExtension(attachment.name),
    }));

    return attachments;
};

export const uploadBuyPoNotesAttachment = async (
    file: File,
    buyPOId: string | number,
    setCanCallApi: IGenericSetState<boolean>,
    setLoading: IGenericSetState<boolean>,
): Promise<string | undefined> => {
    try {
        const payload = getRichTextImagePayload(file);
        setCanCallApi(false);
        setLoading(true);
        const attachments = await apiCall_v2({
            method: 'post',
            url: `${Constants.apiUrls.BUY_PO_INFO}/${buyPOId}${Constants.apiUrls.ATTACHMENTS}?source=notes`,
            payload,
        });
        if (attachments && attachments.length) {
            if (attachments[0]) {
                const attachment = attachments[0];
                const url = `${process.env.REACT_APP_API_URL}/${Constants.apiUrls.BUY_PO_INFO_ROUTE}/${buyPOId}${Constants.apiUrls.ATTACHMENTS}/${attachment.id}/${attachment.accessToken}`;
                return url;
            } else {
                throw new Error('Failed to upload attachment');
            }
        }
    } catch (e) {
        console.error('Failed to upload', e);
        throw e;
    } finally {
        setLoading(false);
        setCanCallApi(true);
    }
};

export const uploadBuyPoDocuments =
    (
        buyPoGlobalInfo: IGlobalInfo,
        setCanCallAttachmentApi: IGenericSetState<boolean>,
        checkFileTypeExtension: ICheckFileTypeExtension,
        setLoading: IGenericSetState<boolean>,
        modifiedBy: string,
    ) =>
    async (dispatch: AppDispatch) => {
        const payload = fetchAttachmentPayload(buyPoGlobalInfo);
        setCanCallAttachmentApi(false);

        const response = await apiCall_v2({
            method: 'post',
            url: `${Constants.apiUrls.BUY_PO_INFO}/${buyPoGlobalInfo.id}${Constants.apiUrls.ATTACHMENTS}`,
            payload,
        });

        if (response && response.length > 0) {
            const fileArray = formatBuyPoFileDetail(
                buyPoGlobalInfo.id,
                response,
                checkFileTypeExtension,
            );
            const buyPoFileArray = [
                ...buyPoGlobalInfo.buyPoFileArray,
                ...fileArray,
            ];
            const isAttachFileDisable =
                isAttachFileFormDisabled(buyPoFileArray);
            dispatch(
                setBuyPoFileDetail({ buyPoFileArray, isAttachFileDisable }),
            );
        } else {
            const buyPoFileArray = buyPoGlobalInfo.buyPoFileArray.filter(
                attachment => attachment.id,
            );

            dispatch(setBuyPoFileDetail({ buyPoFileArray }));
        }

        setLoading(false);
        dispatch(updateLastModifiedDetail(undefined, modifiedBy));
    };

export const fetchBuyPoLocation: IFetchBuyPoLocation =
    async setBuyPoLocations => {
        const buyLocations = await apiCall(
            'get',
            `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.BUYPO_BUY_LOCATION}?filter=IsActive eq true&orderBy=Name`,
        );
        if (buyLocations) {
            setBuyPoLocations([
                ...BuyPOListConstants.LOCATIONS,
                ...(buyLocations ?? []),
            ]);
        }
    };

export const fetchBuyPoDestination: IFetchBuyPoDestinations =
    async setBuyPoDestinations => {
        const buyDestinations = await apiCall(
            'get',
            `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.BUYPO_DESTINATION}?filter=IsActive eq true&orderBy=Name`,
        );
        if (buyDestinations) {
            setBuyPoDestinations([
                ...BuyPOListConstants.DESTINATIONS,
                ...(buyDestinations ?? []),
            ]);
        }
    };

export const fetchTruckingCompanies: IFetchTruckingCompanies = async (
    setBuyPoTruckingCompany,
): Promise<void> => {
    const truckingCompanies = await apiCall(
        'get',
        `${Constants.apiUrls.TRUCKING_COMPANY}?filter=IsActive eq true&orderBy=Name`,
    );

    if (truckingCompanies && truckingCompanies.length > 0) {
        setBuyPoTruckingCompany([
            ...BuyPOListConstants.TRUCKING_COMPANIES,
            ...(truckingCompanies ?? []),
        ]);
    }
};

export const fetchBuyer: IFetchBuyer = async (
    setAllBuyers,
    setBuyPoBuyer,
    setBuyPoSecondBuyer,
): Promise<void> => {
    const buyers = await apiCall_v2({
        method: 'get',
        url: `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.BUYERS}?filter=IsActive eq true&orderBy=Name`,
    });
    if (buyers) {
        setAllBuyers(buyers ?? []);
        if (setBuyPoBuyer) {
            setBuyPoBuyer([...BuyPOListConstants.BUYERS, ...(buyers ?? [])]);
        }
        if (setBuyPoSecondBuyer) {
            setBuyPoSecondBuyer([
                ...BuyPOListConstants.SECOND_BUYERS,
                ...(buyers ?? []),
            ]);
        }
    }
};

export const fetchKillGroup: IFetchKillGroup = async (
    setBuyPoKillGroup,
): Promise<void> => {
    const killGroups = await apiCall(
        'get',
        `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.KILL_GROUP}?filter=IsActive eq true&orderBy=Name`,
    );
    if (killGroups) {
        setBuyPoKillGroup([
            ...BuyPOListConstants.KILL_GROUP,
            ...(killGroups ?? [{}]),
        ]);
    }
};

export const fetchPayee: IFetchPayee = async (setBuyPoPayee): Promise<void> => {
    const payees = await apiCall(
        'get',
        `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.PAYEE}?filter=IsActive eq true&orderBy=Name`,
    );
    if (payees) {
        setBuyPoPayee([...BuyPOListConstants.PAYEE, ...(payees ?? [{}])]);
    }
};

export const handleBuyPoStepVisibility: IHandleBuyPoStepVisibility =
    (steps, values, buyPoViewSteps) =>
    (dispatch: AppDispatch): void => {
        const buyPoValueStepsCopy = deepClone(buyPoViewSteps);

        steps.forEach((step, index) => {
            buyPoValueStepsCopy[step] = values[index];
        });

        dispatch(setBuyPoViewSteps(buyPoValueStepsCopy));
    };

export const updateOrders =
    (
        payload: IOrderPayload[],
        buyPoId: number | string,
        handleUpdateOrders: (response: { orderId: number }[]) => void,
        modifiedBy: string,
    ) =>
    async (dispatch: AppDispatch): Promise<void> => {
        setCanCallService(false);

        const res = await apiCall(
            'put',
            `${Constants.apiUrls.BUY_PO_INFO}/${buyPoId}${Constants.apiUrls.BUY_PO_ORDERS}`,
            payload,
        );

        handleUpdateOrders(res);
        dispatch(updateLastModifiedDetail(undefined, modifiedBy));
    };

export const fetchBuyPoCompanyOwners = async (
    setBuyPoCompanyOwners: ISetBuyPoCompanyOwners,
) => {
    const companyOwners = await apiCall_v2({
        method: 'get',
        url: `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.COMPANY_OWNERS}?filter=IsActive eq true&orderBy=Name`,
    });

    if (companyOwners) {
        setBuyPoCompanyOwners([
            ...BuyPOListConstants.COMPANY_OWNERS,
            ...(companyOwners ?? []),
        ]);
    }
};

export const fetchColors = async (setColor: ISetColor) => {
    const colors = await apiCall_v2({
        method: 'get',
        url: `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.COLOR}?filter=IsActive eq true&orderBy=Name`,
    });

    if (colors) {
        setColor([...BuyPOListConstants.COLOR, ...(colors ?? [])]);
    }
};

export const fetchSex = async (setSex: ISetSex) => {
    const sex = await apiCall_v2({
        method: 'get',
        url: `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.SEX}?filter=IsActive eq true&orderBy=Name`,
    });

    if (sex) {
        setSex([...BuyPOListConstants.SEX, ...(sex ?? [])]);
    }
};

export const fetchBreeds = async (setBreeds: ISetBreeds) => {
    const breeds = await apiCall_v2({
        method: 'get',
        url: `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.BREED}?filter=IsActive eq true&orderBy=Name`,
    });

    if (breeds) {
        setBreeds([...BuyPOListConstants.BREED, ...(breeds ?? [])]);
    }
};

export const fetchSlideUnits = async (setSlideUnits: ISetSlideUnit) => {
    const slideUnits = await apiCall_v2({
        method: 'get',
        url: `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.SLIDE_UNIT}?filter=IsActive eq true&orderBy=SlideUnitName`,
    });

    if (slideUnits) {
        setSlideUnits([
            ...BuyPOListConstants.TERM_SLIDE_UNIT,
            ...(slideUnits ?? []),
        ]);
    }
};

export const handleDeleteBuyPoFees =
    (
        buyPoId: number | string,
        feeId: number,
        popAdditionalFee: (popIndex: number) => void,
        feeIndex: number,
        modifiedBy: string,
    ) =>
    async (dispatch: AppDispatch) => {
        await apiCall(
            'delete',
            `${Constants.apiUrls.BUY_PO_INFO}/${buyPoId}${Constants.apiUrls.FEES}/${feeId}`,
        );

        popAdditionalFee(feeIndex);
        dispatch(updateLastModifiedDetail(undefined, modifiedBy));
    };

export const handleUpdateBuyPoFees =
    (
        buyPoId: number | string,
        payload: IUpdateFeesPayload[],
        handleFeesResponse: (res: { feeId: number }[]) => void,
        modifiedBy: string,
    ) =>
    async (dispatch: AppDispatch) => {
        const res = await apiCall(
            'put',
            `${Constants.apiUrls.BUY_PO_INFO}/${buyPoId}${Constants.apiUrls.FEES_V2}`,
            payload,
        );

        handleFeesResponse(res);
        dispatch(updateLastModifiedDetail(undefined, modifiedBy));
    };

export const setBuyPoPaymentLoading = (
    paymentLoading = CREATE_BUY_PO_INITIAL_STATE.paymentLoading,
) => {
    return {
        type: CreateBuyPoActionTypes.SET_BUY_PO_PAYMENT_LOADING,
        payload: paymentLoading,
    };
};

export const setIsBuyPoSubmitted = (
    isSubmitted = CREATE_BUY_PO_INITIAL_STATE.isSubmitted,
) => {
    return {
        type: CreateBuyPoActionTypes.SET_BUY_PO_SUBMITTED,
        payload: isSubmitted,
    };
};

const handleIsBuyPoSubmitted =
    (status: string) =>
    (dispatch: AppThunkDispatch): void => {
        if (status === '0') {
            dispatch(setIsBuyPoSubmitted(false));
        } else if (status === '1') {
            dispatch(setIsBuyPoSubmitted(true));
        }
    };

const formatGlobalInfoResponse = (
    globalInfo: IGlobalInfoResponse,
): IGlobalInfo => {
    const purchaseDate = globalInfo.purchaseDate
        ? dayjs(globalInfo.purchaseDate).toDate()
        : null;
    const pickupDate = globalInfo.pickupDate
        ? dayjs(globalInfo.pickupDate).toDate()
        : null;

    return {
        id: globalInfo.poId,
        buyPoPurchaseDate: purchaseDate,
        buyPoPickupDate: pickupDate,
        buyPoPickupFromDate: globalInfo.pickUpFromDate || '',
        buyPoPickupToDate: globalInfo.pickUpToDate || '',
        buyPoLocation: globalInfo.buyLocationId || '',
        buyPoLocationName: globalInfo.locationName || '',
        buyPoDestination: globalInfo.destinationId || '',
        buyPoDestinationName: globalInfo.destinationName || '',
        buyPoTruckingCompany: globalInfo.truckingCompanyId || '',
        buyPoTruckingCompanyName: globalInfo.truckingCompanyName || '',
        buyPoBuyer: globalInfo.buyerId || '',
        buyPoBuyerName: globalInfo.buyerName || '',
        buyPoBuyer2: globalInfo.buyer2Id || '',
        buyPoBuyer2Name: globalInfo.buyer2Name || '',
        buyPoKillGroup: globalInfo.killGroupId || '',
        buyPoKillGroupName: globalInfo.killGroupName || '',
        buyPoInvoiceOwner: globalInfo.invoiceOwnerId || '',
        buyPoInvoiceOwnerName: globalInfo.invoiceOwnerName || '',
        payee: globalInfo.payee || '',
        buyPoPayee: globalInfo.payeeId || '',
        buyPoPayeeName: globalInfo.payeeName || '',
        files: [],
        buyPoFileArray: [],
        isAttachFileDisable: false,
        createdBy: globalInfo.createdBy || '',
        createdDatetime: globalInfo.createdDatetime || '',
        modifiedBy: globalInfo.modifiedBy || '',
        modifiedDatetime: globalInfo.modifiedDatetime || '',
        submittedBy: globalInfo.submittedBy || '',
        submittedDateTime: globalInfo.submittedDateTime || '',
        checkNumber: globalInfo.checkNumber || '',
        invoiceNumber: globalInfo.invoiceNumber || '',
        holdPaymentReason: globalInfo.holdPaymentReason || '',
        orderNotes: globalInfo.orderNotes || '',
        DOA: globalInfo.deadOnArrivalCount || '',
        cutCount: globalInfo.cutCount || '',
        ccEmailState: globalInfo.ccEmailState || false,
    };
};

const handleGlobalInfoResponse =
    (globalInfoResponse: IGlobalInfoResponse) =>
    async (dispatch: AppThunkDispatch) => {
        if (globalInfoResponse && globalInfoResponse.poId) {
            dispatch(handleIsBuyPoSubmitted(globalInfoResponse.state));
            dispatch(
                setPaymentInfo({
                    isCattlePaid: globalInfoResponse.cattlePaid,
                    isFreightPaid: globalInfoResponse.freightPaid,
                    isCattleReceived: globalInfoResponse.cattleReceived,
                    isPaymentHold: globalInfoResponse.holdPayment,
                }),
            );

            const formattedGlobalInfo =
                formatGlobalInfoResponse(globalInfoResponse);

            return formattedGlobalInfo;
        }
    };

const formatAttachmentsResponse = (
    attachmentResponse: IAttachmentResponse[],
    buyPoId: number | string,
    checkFileTypeExtension: ICheckFileTypeExtension,
): IAttachment[] => {
    const attachmentsCopy = attachmentResponse.map(attachment => ({
        id: attachment.id,
        url: `${Constants.apiUrls.BUY_PO_INFO}/${buyPoId}${Constants.apiUrls.ATTACHMENTS}/${attachment.id}/${attachment.accessToken}`,
        file_name: attachment.name,
        fileTypeIcon: checkFileTypeExtension(attachment.name),
    }));

    return attachmentsCopy;
};

const handleAttachments =
    (
        buyPoId: number | string,
        globalInfo: IGlobalInfo,
        checkFileTypeExtension: ICheckFileTypeExtension,
        attachmentsResponse: IAttachmentResponse[],
    ) =>
    async (dispatch: AppDispatch) => {
        globalInfo.buyPoFileArray = formatAttachmentsResponse(
            attachmentsResponse,
            buyPoId,
            checkFileTypeExtension,
        );
        globalInfo.isAttachFileDisable = isAttachFileFormDisabled(
            globalInfo.buyPoFileArray,
        );

        dispatch(setBuyPoGlobalInfo(globalInfo));
    };

const formatOrdersResponse = (orders: IBuyPoOrderResponse[]): IOrder[] => {
    const ordersCopy = orders.map(order => ({
        orderId: order.orderId ?? '',
        orderName: order.orderName ?? '',
        notes: order.notes ?? '',
        estimatedHeadCount:
            order.estimatedHeadCount === 0
                ? ''
                : (order.estimatedHeadCount ?? ''),
        headCount: order.headCount === 0 ? '' : (order.headCount ?? ''),
        estimatedGrossWeightPerHd:
            order.estimatedGrossWeightPerHd === 0
                ? ''
                : order.estimatedGrossWeightPerHd === '0' ||
                    order.estimatedGrossWeightPerHd === ''
                  ? ''
                  : (Math.round(Number(order.estimatedGrossWeightPerHd)) ?? ''),
        grossWeight: order.grossWeight ?? '',
        payWeight: order.payWeight ?? '',
        orderPrice: order.orderPrice ?? '',
        totalDollars: order.totalAmount ?? '',
        owner: order.ownerId ?? '',
        ownerName: order.ownerName ?? '',
        color: order.colorId ?? '',
        colorName: order.colorName ?? '',
        sex: order.sexId ?? '',
        sexName: order.sexName ?? '',
        breed: order.breedId ?? '',
        breedName: order.breedName ?? '',
        lot: order.lot ?? '',
        orderNameError: '',
        estimatedHeadCountError: '',
        headCountError: '',
        estimatedGrossWeightPerHdError: '',
        grossWeightError: '',
        payWeightError: '',
        ownerError: '',
        colorError: '',
        sexError: '',
        breedError: '',
        baseWeight: order.baseWeight ?? '',
        price: order.price ?? '',
        shrink: order.shrink ?? '',
        slide: order.slide ?? '',
        slideUnit: order.slideUnitId ?? '',
        slideUnitName: order.slideUnitName ?? '',
        slideDetail: order.slideDetail ?? '',
        slideUnitError: '',
        minWeight: order.minWeight,
        maxWeight: order.maxWeight,
        weightStopDetails: order.weightStopDetails,
        undoFieldNames: order.undoFieldNames,
    }));

    return ordersCopy;
};

export const calculateTotalAndWeight = (
    ordersOrFees: IOrder[] | IBuyPoFee[],
    key: string | undefined = '',
    ordersAndFeeTotal: IOrdersAndFeeTotal | undefined = {
        ...CREATE_BUY_PO_INITIAL_STATE.ordersAndFeeTotal,
    },
): IOrdersAndFeeTotal => {
    const { isKeyAvailable, totalOrdersORShipmentFeeAmount } =
        calculateOrdersORShipmentsAndFeeTotal(
            ordersAndFeeTotal,
            ordersOrFees,
            key,
        );

    const calculatedOrdersTotal = {
        ...totalOrdersORShipmentFeeAmount,
    };
    const canCalculateTotalAndGrossWeight = isKeyAvailable.length > 0;

    if (canCalculateTotalAndGrossWeight) {
        calculatedOrdersTotal['avgGrossWeight'] =
            calculateAvgGrossWeightPerHead(
                isKeyAvailable,
                calculatedOrdersTotal,
            );
        calculatedOrdersTotal['grandTotal'] = calculateGrandTotal(
            isKeyAvailable,
            calculatedOrdersTotal,
        );
    }

    return calculatedOrdersTotal;
};

const handleOrders =
    (orders: IBuyPoOrderResponse[]) => async (dispatch: AppThunkDispatch) => {
        if (orders && orders.length) {
            const formattedOrders = formatOrdersResponse(orders);
            const calculatedOrdersAmountAndWeight =
                calculateTotalAndWeight(formattedOrders);
            dispatch(setBuyPoOrders(formattedOrders));

            return calculatedOrdersAmountAndWeight;
        } else {
            dispatch(
                setBuyPoOrders([
                    { ...CREATE_BUY_PO_INITIAL_STATE.ORDERS_INITIAL },
                ]),
            );
        }
    };

const formatFeesResponse = (feesResponse: IBuyPoFeeResponse[]): IBuyPoFee[] => {
    const feesCopy = feesResponse.map(fee => ({
        feeId: fee.feeId || 0,
        feeType: fee.type || '',
        feeRateType: fee.rateType || '',
        feeRate: fee.rate || '',
        feeQuantity: fee.feeQuantity || '',
        feeAmount: fee.amount || '',
        notes: fee.notes || '',
    }));

    return feesCopy;
};

const calculateFeesTotal = (
    fees: IBuyPoFee[],
    calculatedOrdersTotalAndWeight?: IOrdersAndFeeTotal,
) => {
    const ordersAndFeesTotal = calculatedOrdersTotalAndWeight ?? {
        ...CREATE_BUY_PO_INITIAL_STATE.ordersAndFeeTotal,
    };

    const calculatedOrdersAndFeesTotal = calculateTotalAndWeight(
        fees,
        'feeAmount',
        ordersAndFeesTotal,
    );

    return calculatedOrdersAndFeesTotal;
};

const handleFees =
    (
        fees: IBuyPoFeeResponse[],
        calculatedOrdersTotalAndWeight?: IOrdersAndFeeTotal,
    ) =>
    async (dispatch: AppDispatch) => {
        if (fees && fees.length > 0) {
            const formattedFees = formatFeesResponse(fees);
            const ordersAndFeesTotal = calculateFeesTotal(
                formattedFees,
                calculatedOrdersTotalAndWeight,
            );
            dispatch(setBuyPoOrdersAndFeesTotal(ordersAndFeesTotal));
            dispatch(setBuyPoFees(formattedFees));
        } else {
            const ordersAndFeesTotal = calculatedOrdersTotalAndWeight ?? {
                ...CREATE_BUY_PO_INITIAL_STATE.ordersAndFeeTotal,
            };
            dispatch(setBuyPoOrdersAndFeesTotal(ordersAndFeesTotal));
            dispatch(
                setBuyPoFees([
                    { ...BuyPoFeesConstants.initialState.initialFee },
                ]),
            );
        }
    };

const fetchBuyPOs = async (
    buyPoId: number | string,
): Promise<IBuyPODetails> => {
    const buypoDetails = await apiCall_v2({
        method: 'get',
        url: `${Constants.apiUrls.BUY_PO_INFO}/${buyPoId}${Constants.apiUrls.BUY_PO_PAGE_DETAIL}`,
    });

    return buypoDetails;
};

export const fetchBuyPoDetail =
    (
        buyPoId: number | string,
        checkFileTypeExtension: ICheckFileTypeExtension,
        setIsBuyPOExists: (exists: boolean) => void,
    ) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(setBuyPoLoading(true));
        const buyPoDetails = await fetchBuyPOs(buyPoId);
        const globalInfo = await dispatch(
            handleGlobalInfoResponse(buyPoDetails.globalInfo),
        );
        if (globalInfo && globalInfo.id) {
            await dispatch(
                handleAttachments(
                    buyPoId,
                    globalInfo,
                    checkFileTypeExtension,
                    buyPoDetails.attachments,
                ),
            );
            const calculatedOrdersTotalAndWeight = await dispatch(
                handleOrders(buyPoDetails.orders),
            );
            await dispatch(
                handleFees(
                    buyPoDetails.additionalFees,
                    calculatedOrdersTotalAndWeight,
                ),
            );
            dispatch(
                updateLastModifiedDetail(
                    buyPoDetails.modifiedDateTime ?? '',
                    buyPoDetails.modifiedBy ?? '',
                ),
            );
        } else {
            setIsBuyPOExists(false);
        }

        dispatch(setBuyPoLoading(false));
    };

export const setCanCallService = (
    canCallService = CREATE_BUY_PO_INITIAL_STATE.canCallService,
) => {
    return {
        type: CreateBuyPoActionTypes.SET_BUY_PO_CAN_CALL_SERVICE,
        payload: canCallService,
    };
};

export const setDeleteBuyPOIds = (
    deleteBuyPOIds = [...CREATE_BUY_PO_INITIAL_STATE.deleteBuyPOIds],
) => {
    return {
        type: CreateBuyPoActionTypes.SET_DELETE_BUY_PO_IDs,
        payload: deleteBuyPOIds,
    };
};

export const setBuyPOList = (
    buyPoList = { ...CREATE_BUY_PO_INITIAL_STATE.buyPOList },
) => {
    return {
        type: CreateBuyPoActionTypes.SET_BUY_PO_LIST,
        payload: buyPoList,
    };
};

export const appendBuyPOList = (
    buyPoList = { ...CREATE_BUY_PO_INITIAL_STATE.buyPOList },
) => {
    return {
        type: CreateBuyPoActionTypes.APPEND_BUY_PO_LIST,
        payload: buyPoList,
    };
};

const fetchBuyPOListPayload = (filter: IBuyPoFilter) => {
    const filterCopy = { ...filter };
    let params = '';

    const filterCopyFields = Object.keys(filterCopy);

    filterCopyFields.forEach(field => {
        if (filterCopy[field] || filterCopy[field] === false) {
            if (field !== 'purchaseFromDate' && field !== 'purchaseToDate') {
                params += `${field}=${replaceAmpersandSymbol(
                    filterCopy[field],
                )}&`;
            } else {
                params += `${field}=${formatDate(
                    filterCopy[field],
                    'YYYY-MM-DD',
                )}&`;
            }
        }
    });

    return params;
};

const addDeleteFieldsInList = (buyPoListCopy: IBuyPOList[]) => {
    return buyPoListCopy.map(buyPoDetail => ({
        ...buyPoDetail,
        canDelete: false,
    }));
};

export const fetchBuyPOList =
    (filter: IBuyPoFilter, isAppend: boolean) =>
    async (dispatch: AppDispatch) => {
        const payload = fetchBuyPOListPayload(filter);

        try {
            const response = await apiCall_v2({
                method: 'get',
                url: isBP1246_buypoFilterQuerySync
                    ? `${Constants.apiUrls.BUY_PO_INFO_V1}?${payload}`
                    : `${Constants.apiUrls.BUY_PO_INFO}?${payload}`,
            });

            if (response && response.data) {
                const buyPOList = addDeleteFieldsInList(response.data.data);

                if (response.data.data.length < BuyPOListConstants.limit) {
                    dispatch(setCanCallService(false));
                }

                if (isAppend) {
                    dispatch(appendBuyPOList([...buyPOList]));
                } else {
                    dispatch(setBuyPOList([...buyPOList]));
                }
            }
            return response.data.data;
        } catch (error) {
            return [];
        } finally {
            dispatch(setBuyPoLoading(false));
            dispatch(setBuyPoPaymentLoading(false));
        }
    };

export const setBuyPOFilter = (
    filter = { ...CREATE_BUY_PO_INITIAL_STATE.filter },
) => {
    return {
        type: CreateBuyPoActionTypes.SET_BUY_PO_FILTER,
        payload: filter,
    };
};

export const appendBuyPOFilter = (
    filter = { ...CREATE_BUY_PO_INITIAL_STATE.filter },
) => {
    return {
        type: CreateBuyPoActionTypes.APPEND_BUY_PO_FILTER,
        payload: filter,
    };
};

export const deleteBuyPos =
    (
        filter: IBuyPoFilter,
        payload: IDeleteBuyPOIds[],
        resetFilter: () => void,
        deleteBuyPOIds: IDeleteBuyPOIds[] = [],
    ) =>
    async (dispatch: AppDispatch) => {
        dispatch(setBuyPoPaymentLoading(true));

        const response = await apiCall(
            'post',
            `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.DELETE_V2}`,
            payload,
            true,
        );

        if (response && response.status === Constants.responseCode.NO_CONTENT) {
            if (deleteBuyPOIds.length > 0) {
                const idsToRemove = payload.map(
                    (item: IDeleteBuyPOIds) => item.id,
                );

                const updatedDeleteBuyPOIds = deleteBuyPOIds.filter(
                    idDetail => !idsToRemove.includes(idDetail.id),
                );
                dispatch(setDeleteBuyPOIds(updatedDeleteBuyPOIds));
            } else {
                dispatch(setDeleteBuyPOIds([]));
            }
            if (
                filter.limit === BuyPOListConstants.limit &&
                filter.offset === BuyPOListConstants.offset
            ) {
                dispatch(fetchBuyPOList(filter, false));
            } else {
                resetFilter();
            }

            response.status = Number(response.status);
            dispatch(toastMessage(getToastContent(response.status, 'delete')));
        }
    };

export const resetAllBuyPOListStates = () => {
    return {
        type: CreateBuyPoActionTypes.RESET_ALL_BUY_PO_LIST_STATES,
        payload: {
            buyPOList: [],
            deleteBuyPOIds: [],
            paymentLoading: true,
            loading: false,
            canCallService: true,
        },
    };
};

export const resetAllBuyPOCreateState = () => {
    return {
        type: CreateBuyPoActionTypes.RESET_ALL_BUY_PO_CREATE_STATES,
        payload: {
            loading: false,
            buyPoGlobalInfo: { ...CREATE_BUY_PO_INITIAL_STATE.buyPoGlobalInfo },
            buyPoGlobalInfoError: {
                ...CREATE_BUY_PO_INITIAL_STATE.buyPoGlobalInfoError,
            },
            buyPOViewSteps: { ...CREATE_BUY_PO_INITIAL_STATE.buyPOViewSteps },
            ordersAndFeeTotal: {
                ...CREATE_BUY_PO_INITIAL_STATE.ordersAndFeeTotal,
            },
            paymentInfo: { ...CREATE_BUY_PO_INITIAL_STATE.paymentInfo },
            lastModifiedDate: '',
            orders: [{ ...CREATE_BUY_PO_INITIAL_STATE.ORDERS_INITIAL }],
            fees: [{ ...BuyPoFeesConstants.initialState.initialFee }],
            selectedDestinationDetail: {},
        },
    };
};

export const handleCreateLocation = async (
    setLoading: IGenericSetState<boolean>,
    fetchLocationPayload: () => ILocationDetail,
    handleCreateLocationResponse: (
        locationResponse: IFetchLocationResponse,
    ) => void,
    shipPOApiUrl?: string,
) => {
    setLoading(true);
    const payload = fetchLocationPayload();
    const apiUrl = shipPOApiUrl
        ? shipPOApiUrl
        : `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.BUYPO_BUY_LOCATION}`;

    const locationResponse = await apiCall('post', apiUrl, payload, true);
    handleCreateLocationResponse(locationResponse);
    setLoading(false);
};

export const handleEditLocation = async (
    setLoading: IGenericSetState<boolean>,
    fetchEditLocationPayload: () => IEditLocationDetail,
    handleCreateLocationResponse: (
        locationResponse: IFetchLocationResponse,
    ) => void,
) => {
    setLoading(true);
    const payload = fetchEditLocationPayload();
    const locationResponse = await apiCall(
        'put',
        `${Constants.apiUrls.BUY_PO_INFO}/${payload.id}${Constants.apiUrls.BUYPO_BUY_LOCATION}`,
        payload,
        true,
    );
    handleCreateLocationResponse(locationResponse);
    setLoading(false);
};

export const handleCreateDestination = async (
    setLoader: IGenericSetState<boolean>,
    fetchDestinationPayload: () => IDestinationDetail,
    handleCreateDestinationResponse: (
        destinationResponse: IFetchDestinationResponse,
    ) => void,
    shipPOApiUrl?: string,
) => {
    setLoader(true);
    const payload = fetchDestinationPayload();
    const apiUrl = shipPOApiUrl
        ? shipPOApiUrl
        : `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.BUYPO_DESTINATION}`;
    const destinationResponse = await apiCall('post', apiUrl, payload, true);
    handleCreateDestinationResponse(destinationResponse);
    setLoader(false);
};

export const handleEditDestination = async (
    setLoader: IGenericSetState<boolean>,
    fetchEditDestinationPayload: () => IEditDestinationDetail,
    handleCreateDestinationResponse: (
        destinationResponse: IFetchDestinationResponse,
    ) => void,
) => {
    setLoader(true);
    const payload = fetchEditDestinationPayload();
    const destinationResponse = await apiCall(
        'put',
        `${Constants.apiUrls.BUY_PO_INFO}/${payload.id}${Constants.apiUrls.BUYPO_DESTINATION}`,
        payload,
        true,
    );
    handleCreateDestinationResponse(destinationResponse);
    setLoader(false);
};

export const handleEditPayee =
    (
        setLoading: IGenericSetState<boolean>,
        handleCreatePayeeResponse: (payeeResponse: IFetchPayeeResponse) => void,
        fetchEditPayeePayload: () => IPayeeDetail,
        handleActivatePayee: () => Promise<void>,
    ) =>
    async (dispatch: AppDispatch) => {
        setLoading(true);
        const payload = fetchEditPayeePayload();
        const response = await apiCall_v2({
            method: 'put',
            url: `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.PAYEE}/${payload.id}`,
            payload,
            isResRequired: true,
        });
        setLoading(false);
        if (!response) return;
        const status = Number(response.status);
        if (status === Constants.responseCode.DUPLICATE) {
            dispatch(
                toastMessage(
                    getToastContent(
                        response.status,
                        '',
                        Constants.customMessage.PAYEE_DUPLICATE,
                    ),
                ),
            );
        } else if (status === Constants.responseCode.CONFLICT) {
            handleActivatePayee();
        } else if (
            status >= Constants.responseCode.SUCCESS &&
            status < Constants.responseCode.MULTIPLE_CHOICES
        ) {
            dispatch(toastMessage(getToastContent(response.status)));
            handleCreatePayeeResponse(response);
        }
    };

export const handleEditKillGroup =
    (
        setLoading: IGenericSetState<boolean>,
        handleCreateKillGroupResponse: (
            killGroupResponse: IFetchKillGroupResponse,
        ) => void,
        fetchKillGroupPayload: () => IBuyPoKillGroupDetail,
        handleActivateKillGroup: () => Promise<void>,
    ) =>
    async (dispatch: AppDispatch) => {
        setLoading(true);
        const payload = fetchKillGroupPayload();
        const response = await apiCall(
            'put',
            `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.KILL_GROUP}/${payload.killGroupId}`,
            payload,
            true,
        );
        setLoading(false);
        if (!response) return;
        const status = Number(response.status);

        if (status === Constants.responseCode.DUPLICATE) {
            dispatch(
                toastMessage(
                    getToastContent(
                        response.status,
                        '',
                        Constants.customMessage.KILL_GROUP_DUPLICATE,
                    ),
                ),
            );
        } else if (status === Constants.responseCode.CONFLICT) {
            handleActivateKillGroup();
        } else if (
            status >= Constants.responseCode.SUCCESS &&
            status < Constants.responseCode.MULTIPLE_CHOICES
        ) {
            dispatch(toastMessage(getToastContent(response.status)));
            handleCreateKillGroupResponse(response);
        }
    };

export const handleEditBuyer =
    (
        setLoading: IGenericSetState<boolean>,
        handleCreateBuyerResponse: (buyerResponse: IFetchBuyerResponse) => void,
        fetchEditBuyerPayload: () => IBuyerDetail,
        handleActivateBuyer: () => Promise<void>,
    ) =>
    async (dispatch: AppDispatch) => {
        setLoading(true);
        const payload = fetchEditBuyerPayload();
        const response = await apiCall_v2({
            method: 'put',
            url: `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.BUYERS}/${payload.buyerId}`,
            payload,
            isResRequired: true,
        });
        setLoading(false);
        if (!response) return;
        const status = Number(response.status);

        if (status === Constants.responseCode.DUPLICATE) {
            dispatch(
                toastMessage(
                    getToastContent(
                        response.status,
                        '',
                        Constants.customMessage.BUYER_DUPLICATE,
                    ),
                ),
            );
        } else if (status === Constants.responseCode.CONFLICT) {
            handleActivateBuyer();
        } else if (
            status >= Constants.responseCode.SUCCESS &&
            status < Constants.responseCode.MULTIPLE_CHOICES
        ) {
            dispatch(toastMessage(getToastContent(response.status)));
            handleCreateBuyerResponse(response);
        }
    };

export const handleEditColor =
    (
        setLoading: IGenericSetState<boolean>,
        handleCreateColorResponse: (colorResponse: IFetchColorResponse) => void,
        fetchEditColorPayload: () => IColorDetail,
        handleActivateColor: () => Promise<void>,
    ) =>
    async (dispatch: AppDispatch) => {
        setLoading(true);
        const payload = fetchEditColorPayload();
        const response = await apiCall_v2({
            method: 'put',
            url: `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.COLOR}/${payload.id}`,
            payload,
            isResRequired: true,
        });
        setLoading(false);
        if (!response) return;
        const status = Number(response.status);

        if (status === Constants.responseCode.DUPLICATE) {
            dispatch(
                toastMessage(
                    getToastContent(
                        response.status,
                        '',
                        Constants.customMessage.COLOR_DUPLICATE,
                    ),
                ),
            );
        } else if (status === Constants.responseCode.CONFLICT) {
            handleActivateColor();
        } else if (
            status >= Constants.responseCode.SUCCESS &&
            status < Constants.responseCode.MULTIPLE_CHOICES
        ) {
            dispatch(toastMessage(getToastContent(response.status)));
            handleCreateColorResponse(response);
        }
    };

export const handleEditSex =
    (
        setLoading: IGenericSetState<boolean>,
        handleCreateSexResponse: (sexResponse: IFetchSexResponse) => void,
        fetchEditSexPayload: () => ISexDetail,
        handleActivateSex: () => Promise<void>,
    ) =>
    async (dispatch: AppDispatch) => {
        setLoading(true);
        const payload = fetchEditSexPayload();

        const response = await apiCall_v2({
            method: 'put',
            url: `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.SEX}/${payload.id}`,
            payload,
            isResRequired: true,
        });
        setLoading(false);
        if (!response) return;
        const status = Number(response.status);

        if (status === Constants.responseCode.DUPLICATE) {
            dispatch(
                toastMessage(
                    getToastContent(
                        response.status,
                        '',
                        Constants.customMessage.SEX_DUPLICATE,
                    ),
                ),
            );
        } else if (status === Constants.responseCode.CONFLICT) {
            handleActivateSex();
        } else if (
            status >= Constants.responseCode.SUCCESS &&
            status < Constants.responseCode.MULTIPLE_CHOICES
        ) {
            dispatch(toastMessage(getToastContent(response.status)));
            handleCreateSexResponse(response);
        }
    };

export const handleEditBreed =
    (
        setLoading: IGenericSetState<boolean>,
        handleCreateBreedResponse: (breedResponse: IFetchBreedResponse) => void,
        fetchEditBreedPayload: () => IBreedDetail,
        handleActivateBreed: () => Promise<void>,
    ) =>
    async (dispatch: AppDispatch) => {
        setLoading(true);
        const payload = fetchEditBreedPayload();
        const response = await apiCall_v2({
            method: 'put',
            url: `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.BREED}/${payload.id}`,
            payload,
            isResRequired: true,
        });
        setLoading(false);
        if (!response) return;
        const status = Number(response.status);

        if (status === Constants.responseCode.DUPLICATE) {
            dispatch(
                toastMessage(
                    getToastContent(
                        response.status,
                        '',
                        Constants.customMessage.BREED_DUPLICATE,
                    ),
                ),
            );
        } else if (status === Constants.responseCode.CONFLICT) {
            handleActivateBreed();
        } else if (
            status >= Constants.responseCode.SUCCESS &&
            status < Constants.responseCode.MULTIPLE_CHOICES
        ) {
            dispatch(toastMessage(getToastContent(response.status)));
            handleCreateBreedResponse(response);
        }
    };

export const handleEditSlideUnit =
    (
        setLoading: IGenericSetState<boolean>,
        handleCreateSlideUnitResponse: (
            slideUnitResponse: IFetchSlideUnitResponse,
        ) => void,
        fetchEditSlideUnitPayload: () => ISlideUnitDetail,
        handleActivateSlideUnit: () => Promise<void>,
    ) =>
    async (dispatch: AppDispatch) => {
        setLoading(true);
        const payload = fetchEditSlideUnitPayload();
        const response = await apiCall_v2({
            method: 'put',
            url: `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.SLIDE_UNIT}/${payload.slideUnitId}`,
            payload,
            isResRequired: true,
        });
        setLoading(false);
        if (!response) return;
        const status = Number(response.status);

        if (status === Constants.responseCode.DUPLICATE) {
            dispatch(
                toastMessage(
                    getToastContent(
                        response.status,
                        '',
                        Constants.customMessage.SLIDE_UNIT_DUPLICATE,
                    ),
                ),
            );
        } else if (status === Constants.responseCode.CONFLICT) {
            handleActivateSlideUnit();
        } else if (
            status >= Constants.responseCode.SUCCESS &&
            status < Constants.responseCode.MULTIPLE_CHOICES
        ) {
            dispatch(toastMessage(getToastContent(response.status)));
            handleCreateSlideUnitResponse(response);
        }
    };

export const updateAdditionalColumnsRequiredInExcel = maxIndex => {
    const { additionalFeeType, rateType, rate, quantity, amount, notes } =
        buyPoExcelConstants.additionalFeesDetails;
    const buyPoDetails = buyPoExcelConstants.buyPoDetails[0];

    for (let i = 1; i <= maxIndex; i++) {
        buyPoDetails[`additionalFeeType${i}`] = `${additionalFeeType} ${i}`;
        buyPoDetails[`rateType${i}`] = `${rateType} ${i}`;
        buyPoDetails[`rate${i}`] = `${rate} ${i}`;
        buyPoDetails[`quantity${i}`] = `${quantity} ${i}`;
        buyPoDetails[`amount${i}`] = `${amount} ${i}`;
        buyPoDetails[`notes${i}`] = `${notes} ${i}`;
    }
};

const formatAdditionalFees = additionalFees => {
    const feeData: { [key: string]: string | null } = {};
    let maxIndex = 0;
    if (Array.isArray(additionalFees)) {
        additionalFees.forEach((fee, index: number) => {
            const feeNumber = index + 1;
            maxIndex = Math.max(maxIndex, feeNumber);
            feeData[`additionalFeeType${feeNumber}`] = fee.type || '';
            feeData[`rateType${feeNumber}`] = fee.rateType || '';
            feeData[`rate${feeNumber}`] = fee.rate?.toString() || '';
            feeData[`quantity${feeNumber}`] =
                fee.quantity === 0 ? '' : fee.quantity?.toString() || '';
            feeData[`amount${feeNumber}`] =
                fee.amount === 0
                    ? ''
                    : Math.round(fee.amount)?.toString() || '';
            feeData[`notes${feeNumber}`] = fee.notes || '';
        });
    }
    updateAdditionalColumnsRequiredInExcel(maxIndex);
    return feeData;
};

export const formatBuyPos = (response: ICsvData[]) => {
    return response.map(buyPo => {
        const result = {
            purchaseOrderID: buyPo?.poId || '',
            purchaseDate: formatDate(dayjs(buyPo?.purchaseDate)) || '',
            orderName: buyPo?.orderName || '',
            lot: buyPo?.lot || '',
            hdCount: buyPo?.headCount || '',
            payWeight: buyPo?.payWeight || '',
            averageWeight: buyPo?.averageWeight || '',
            costofCattle: buyPo?.costOfCattle || '',
            costPerLb: buyPo?.costPerlb || '',
            costPerCWT: buyPo?.costPerCWT || '',
            buyLocation: buyPo?.buyLocation || '',
            destination: buyPo?.destination || '',
            payee: buyPo?.payee || '',
            ownerName: buyPo?.ownerName || '',
            killGroup: buyPo?.killGroupName || '',
            truckingCompany: buyPo?.truckingCompany || '',
            notes: buyPo?.notes || '',
        } as ICsvData;
        const formattedFees = formatAdditionalFees(buyPo?.additionalFees);
        Object.assign(result, {
            ...formattedFees,
        });

        return result;
    });
};

export const setBuyPoExcelRecord = (
    buyPoReport = { ...CREATE_BUY_PO_INITIAL_STATE.buyPoReport },
) => {
    return {
        type: CreateBuyPoActionTypes.SET_BUY_PO_EXCEL_RECORD,
        payload: buyPoReport,
    };
};

export const fetchBuyPoExcelRecord =
    (setLoading: IGenericSetState<boolean>) =>
    async (dispatch: AppDispatch) => {
        const response = await apiCall(
            'get',
            `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.BUY_PO_EXCEL_RECORD}`,
            {},
            true,
        );

        if (
            response &&
            response.status >= Constants.responseCode.SUCCESS &&
            response.status < Constants.responseCode.MULTIPLE_CHOICES
        ) {
            if (response.data.length) {
                const excelRecord: ICsvData[] = formatBuyPos(response.data);
                dispatch(
                    setBuyPoExcelRecord([
                        ...buyPoExcelConstants.buyPoDetails,
                        ...excelRecord,
                    ]),
                );
            } else {
                dispatch(
                    toastMessage(
                        getToastContent(
                            response.status,
                            '',
                            Constants.message.commonLog.NO_RECORDS_FOUND,
                        ),
                    ),
                );
                setLoading(false);
            }
        } else {
            dispatch(toastMessage(getToastContent(response.status)));
            setLoading(false);
        }
    };

export const fetchInactiveLocationInfo = async (locationName: string) => {
    const buyLocations = await apiCall_v2({
        method: 'get',
        url: `${Constants.apiUrls.BUY_PO_INFO}/${locationName}${Constants.apiUrls.BUYPO_BUY_LOCATION}`,
    });
    return buyLocations;
};

export const fetchInactiveDestinationInfo = async (destinationName: string) => {
    const buyDestinations = await apiCall_v2({
        method: 'get',
        url: `${Constants.apiUrls.BUY_PO_INFO}/${destinationName}${Constants.apiUrls.BUYPO_DESTINATION}`,
    });
    return buyDestinations;
};

export const fetchInactiveTruckingCompanyInfo = async (
    truckingCompanyName: string,
) => {
    const truckingCompanyInfo = await apiCall_v2({
        method: 'get',
        url: `${Constants.apiUrls.TRUCKING_COMPANY}?filter=Name eq '${truckingCompanyName}'`,
    });
    return truckingCompanyInfo;
};

export const fetchInactiveOwnerInfo = async (ownerName: string) => {
    const owners = await apiCall_v2({
        method: 'get',
        url: `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.COMPANY_OWNERS}?filter=Name eq '${ownerName}'`,
    });
    return owners;
};

export const fetchInactiveBuyerInfo = async (buyerName: string) => {
    const buyers = await apiCall_v2({
        method: 'get',
        url: `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.BUYERS}?filter=Name eq '${buyerName}'`,
    });
    return buyers;
};

export const fetchInactivePayeeInfo = async (payeeName: string) => {
    const payees = await apiCall_v2({
        method: 'get',
        url: `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.BUYERS}?filter=Name eq '${payeeName}'`,
    });
    return payees;
};

export const fetchInactiveKillGroupInfo = async (killGroupName: string) => {
    const killGroups = await apiCall_v2({
        method: 'get',
        url: `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.KILL_GROUP}?filter=Name eq '${killGroupName}'`,
    });
    return killGroups;
};

export const fetchInactiveColorInfo = async (colorName: string) => {
    const colors = await apiCall_v2({
        method: 'get',
        url: `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.COLOR}?filter=Name eq '${colorName}'`,
    });
    return colors;
};

export const fetchInactiveSexInfo = async (sexName: string) => {
    const sex = await apiCall_v2({
        method: 'get',
        url: `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.SEX}?filter=Name eq '${sexName}'`,
    });
    return sex;
};

export const fetchInactiveBreedInfo = async (breedName: string) => {
    const breeds = await apiCall_v2({
        method: 'get',
        url: `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.BREED}?filter=Name eq '${breedName}'`,
    });
    return breeds;
};

export const fetchInactiveSlideUnitInfo = async (slideUnitName: string) => {
    const slideUnits = await apiCall_v2({
        method: 'get',
        url: `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.SLIDE_UNIT}?filter=SlideUnitName eq '${slideUnitName}'`,
    });
    return slideUnits;
};

export const activateDestination =
    (destination: IInactiveBuyPoDestination) =>
    async (dispatch: AppDispatch) => {
        const response = await apiCall_v2({
            method: 'put',
            url: `${Constants.apiUrls.BUY_PO_INFO}/${destination.id}${Constants.apiUrls.BUYPO_DESTINATION}`,
            payload: destination,
            isResRequired: true,
        });
        dispatch(toastMessage(getToastContent(Number(response.status))));
    };

export const activateLocation =
    (location: IInactiveBuyPoLocation) => async (dispatch: AppDispatch) => {
        const response = await apiCall_v2({
            method: 'put',
            url: `${Constants.apiUrls.BUY_PO_INFO}/${location.id}${Constants.apiUrls.BUYPO_BUY_LOCATION}`,
            payload: location,
            isResRequired: true,
        });
        dispatch(toastMessage(getToastContent(Number(response.status))));
    };

export const activatePayee =
    (payee: IPayeeDetail) => async (dispatch: AppDispatch) => {
        const response = await apiCall_v2({
            method: 'put',
            url: `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.PAYEE}/${payee.id}`,
            payload: payee,
            isResRequired: true,
        });
        dispatch(toastMessage(getToastContent(Number(response.status))));
    };

export const activateTruckingCompany =
    (truckingCompany: IBuyPoTruckingCompanyDetail) =>
    async (dispatch: AppDispatch) => {
        const response = await apiCall_v2({
            method: 'put',
            url: `${Constants.apiUrls.TRUCKING_COMPANY}/${truckingCompany.companyId}`,
            payload: truckingCompany,
            isResRequired: true,
        });
        dispatch(toastMessage(getToastContent(Number(response.status))));
    };

export const activateOwner = (owner: any) => async (dispatch: AppDispatch) => {
    const response = await apiCall_v2({
        method: 'put',
        url: `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.COMPANY_OWNERS}/${owner.id}`,
        payload: owner,
        isResRequired: true,
    });
    dispatch(toastMessage(getToastContent(Number(response.status))));
};

export const activateBuyer =
    (buyer: IBuyerDetail) => async (dispatch: AppDispatch) => {
        const response = await apiCall_v2({
            method: 'put',
            url: `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.BUYERS}/${buyer.buyerId}`,
            payload: buyer,
            isResRequired: true,
        });
        dispatch(toastMessage(getToastContent(Number(response.status))));
    };

export const activateKillGroup =
    (killGroup: IBuyPoKillGroupDetail) => async (dispatch: AppDispatch) => {
        const response = await apiCall_v2({
            method: 'put',
            url: `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.KILL_GROUP}/${killGroup.killGroupId}`,
            payload: killGroup,
            isResRequired: true,
        });
        dispatch(toastMessage(getToastContent(Number(response.status))));
    };

export const activateSlideUnit =
    (slideUnit: ISlideUnitDetail) => async (dispatch: AppDispatch) => {
        const response = await apiCall_v2({
            method: 'put',
            url: `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.SLIDE_UNIT}/${slideUnit.slideUnitId}`,
            payload: slideUnit,
            isResRequired: true,
        });
        dispatch(toastMessage(getToastContent(Number(response.status))));
    };

export const activateColor =
    (color: IColorDetail) => async (dispatch: AppDispatch) => {
        const response = await apiCall_v2({
            method: 'put',
            url: `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.COLOR}/${color.id}`,
            payload: color,
            isResRequired: true,
        });
        dispatch(toastMessage(getToastContent(Number(response.status))));
    };

export const activateSex =
    (sex: ISexDetail) => async (dispatch: AppDispatch) => {
        const response = await apiCall_v2({
            method: 'put',
            url: `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.SEX}/${sex.id}`,
            payload: sex,
            isResRequired: true,
        });
        dispatch(toastMessage(getToastContent(Number(response.status))));
    };

export const activateBreed =
    (breed: IBreedDetail) => async (dispatch: AppDispatch) => {
        const response = await apiCall_v2({
            method: 'put',
            url: `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.BREED}/${breed.id}`,
            payload: breed,
            isResRequired: true,
        });
        dispatch(toastMessage(getToastContent(Number(response.status))));
    };

export const checkDuplicateBuyPo = async (buyPoGlobalInfo: IGlobalInfo) => {
    const {
        buyPoLocation,
        buyPoDestination,
        buyPoTruckingCompany,
        buyPoPurchaseDate,
        id,
    } = buyPoGlobalInfo;
    const formattedPurchaseDate = formatDate(dayjs(buyPoPurchaseDate));

    const filter = `filter=POId neq ${id}; PurchaseDate eq ${formattedPurchaseDate}; BuyLocationId eq ${buyPoLocation}; DestinationId eq ${buyPoDestination}; TruckingCompanyId eq ${buyPoTruckingCompany}`;
    const response = await apiCall_v2({
        method: 'get',
        url: `${Constants.apiUrls.BUY_PO_INFO}?${filter}&show=all`,
    });
    return response;
};
