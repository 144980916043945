import Constants from '../utils/Constants';
import {
    BuyPOGlobalInfoConstants,
    BuyPoConstants,
    BuyPoOrderConstants,
} from '../apps/shippingTracker/modules/BuyPo/constants/BuyPoConstants';

export const setComponentsStepValues = (fields, viewSteps, values) => {
    const valueStepCopy = { ...viewSteps };
    fields.forEach((fieldName, index) => {
        valueStepCopy[fieldName] = values[index];
    });
    return valueStepCopy;
};

const canCalculateTotalAndAvgGrossWeight = key => {
    return BuyPoOrderConstants.fieldsWithWeightAndCosts.includes(key);
};

export const calculateTotalAmountFloatAndInt = (
    orderOrFeeCountDetail,
    orderAndFeeAmountField,
) => {
    const floatAmountFields = [
        'totalDollars',
        'feeAmount',
        'estimatedGrossWeightPerHd',
        'payWeight',
    ];
    let totalAmount = 0;

    if (orderOrFeeCountDetail[orderAndFeeAmountField]) {
        if (floatAmountFields.includes(orderAndFeeAmountField)) {
            totalAmount = parseFloat(
                orderOrFeeCountDetail[orderAndFeeAmountField],
            );
            totalAmount = +totalAmount.toFixed(2);
        } else {
            totalAmount = parseInt(
                orderOrFeeCountDetail[orderAndFeeAmountField],
            );
        }
    }

    return totalAmount;
};

export const fixFloatValue = (orderAndFeeAmountField, totalAmount) => {
    const dollarAmountFields = [
        'totalDollars',
        'feeAmount',
        'estimatedGrossWeightPerHd',
        'payWeight',
    ];

    if (dollarAmountFields.includes(orderAndFeeAmountField)) {
        return +parseFloat(totalAmount).toFixed(2);
    }

    return totalAmount;
};

export const calculateEstimatedGrossWeightPerHd = (
    ordersOrShipmentsOrFees,
    totalAmountDetail,
    amountFieldName,
) => {
    let grossOrPayWeightTotal = 0;
    let hdCountOrEstimatedHdCountTotal = 0;
    totalAmountDetail.estimatedGrossWeightPerHdTotal = 0;

    ordersOrShipmentsOrFees.forEach(arrDetail => {
        grossOrPayWeightTotal += arrDetail.payWeight
            ? calculateTotalAmountFloatAndInt(arrDetail, 'payWeight')
            : calculateTotalAmountFloatAndInt(arrDetail, 'grossWeight');

        hdCountOrEstimatedHdCountTotal += arrDetail.headCount
            ? calculateTotalAmountFloatAndInt(arrDetail, 'headCount')
            : calculateTotalAmountFloatAndInt(arrDetail, 'estimatedHeadCount');
    });

    const estimatedGrossWeightPerHdTotal = hdCountOrEstimatedHdCountTotal
        ? grossOrPayWeightTotal / hdCountOrEstimatedHdCountTotal
        : 0;
    totalAmountDetail.estimatedGrossWeightPerHdTotal = fixFloatValue(
        'estimatedGrossWeightPerHd',
        estimatedGrossWeightPerHdTotal,
    );

    return {
        amountDetail: totalAmountDetail,
        availableKeys: canCalculateTotalAndAvgGrossWeight(amountFieldName)
            ? [amountFieldName]
            : [],
    };
};

//Calculate total amount of order or shipments or fees when overall array update
const calculationAmountWithoutKey = (
    ordersOrShipmentsOrFees,
    totalAmountDetail,
    isKeyAvailable,
    moduleType,
) => {
    const totalAmountDetailFields = Object.keys(totalAmountDetail);

    ordersOrShipmentsOrFees.forEach((arrDetail, i) => {
        totalAmountDetailFields.forEach(amountKey => {
            const orderKey = amountKey.split('Total')[0];

            if (Object.hasOwn(arrDetail, orderKey)) {
                if (
                    orderKey === 'estimatedGrossWeightPerHd' &&
                    moduleType === BuyPoConstants.moduleType
                ) {
                    calculateEstimatedGrossWeightPerHd(
                        ordersOrShipmentsOrFees,
                        totalAmountDetail,
                        moduleType,
                    );
                } else {
                    totalAmountDetail[amountKey] =
                        i === 0 ? 0 : totalAmountDetail[amountKey];
                    totalAmountDetail[amountKey] +=
                        calculateTotalAmountFloatAndInt(arrDetail, orderKey);
                    totalAmountDetail[amountKey] = fixFloatValue(
                        orderKey,
                        totalAmountDetail[amountKey],
                    );
                }
            }

            if (canCalculateTotalAndAvgGrossWeight(orderKey)) {
                isKeyAvailable.push(orderKey);
            }
        });
    });

    return { amountDetail: totalAmountDetail, availableKeys: isKeyAvailable };
};

//Calculate total amount of orders or shipments or fees when specific field update
const calculateAmountWithKey = (
    ordersOrShipmentsOrFees,
    totalAmountDetail,
    isKeyAvailable,
    amountFieldName,
) => {
    totalAmountDetail[`${amountFieldName}Total`] = 0;

    ordersOrShipmentsOrFees.forEach(arrDetail => {
        totalAmountDetail[`${amountFieldName}Total`] +=
            calculateTotalAmountFloatAndInt(arrDetail, amountFieldName);
    });

    totalAmountDetail[`${amountFieldName}Total`] = fixFloatValue(
        amountFieldName,
        totalAmountDetail[`${amountFieldName}Total`],
    );

    if (canCalculateTotalAndAvgGrossWeight(amountFieldName)) {
        isKeyAvailable = [amountFieldName];
    }

    return { amountDetail: totalAmountDetail, availableKeys: isKeyAvailable };
};

//Calculate orders or shipments And fees total amount
export const calculateOrdersORShipmentsAndFeeTotal = (
    ordersOrShipmentAndFeeTotalAmount,
    amountArray,
    amountFieldName,
    moduleType = BuyPoConstants.moduleType,
) => {
    const totalOrdersORShipmentFeeAmount = {
        ...ordersOrShipmentAndFeeTotalAmount,
    };
    const totalAmountArray = [...amountArray];
    const isKeyAvailable = [];
    let calculatedAmountDetail;

    if (amountFieldName) {
        if (
            amountFieldName === 'estimatedGrossWeightPerHd' &&
            moduleType === BuyPoConstants.moduleType
        ) {
            calculatedAmountDetail = calculateEstimatedGrossWeightPerHd(
                totalAmountArray,
                totalOrdersORShipmentFeeAmount,
                amountFieldName,
            );
        } else {
            calculatedAmountDetail = calculateAmountWithKey(
                totalAmountArray,
                totalOrdersORShipmentFeeAmount,
                isKeyAvailable,
                amountFieldName,
            );
        }
    } else {
        calculatedAmountDetail = calculationAmountWithoutKey(
            totalAmountArray,
            totalOrdersORShipmentFeeAmount,
            isKeyAvailable,
            moduleType,
        );
    }

    return {
        isKeyAvailable: calculatedAmountDetail.availableKeys,
        totalOrdersORShipmentFeeAmount: calculatedAmountDetail.amountDetail,
    };
};

export const calculateAvgGrossWeightPerHead = (type, total) => {
    if (
        type.includes('headCount') ||
        type.includes('grossWeight') ||
        type.includes('estimatedHeadCount') ||
        type.includes('estimatedGrossWeightPerHd')
    ) {
        if (total.headCountTotal > 0 && total.estimatedHeadCountTotal > 0) {
            const combinedGrossWeightTotal =
                total.grossWeightTotal + total.estimatedGrossWeightPerHdTotal;
            const combinedHeadCountTotal =
                total.headCountTotal + total.estimatedHeadCountTotal;
            const totalCombinedAvgGrossWeight = parseFloat(
                (combinedGrossWeightTotal / combinedHeadCountTotal).toString(),
            ).toFixed(2);
            total['avgGrossWeight'] = +totalCombinedAvgGrossWeight;
        } else if (total.headCountTotal > 0) {
            const totalAvgGrossWeight =
                total.headCountTotal > 0
                    ? parseFloat(
                          (
                              total.grossWeightTotal / total.headCountTotal
                          ).toString(),
                      ).toFixed(2)
                    : 0.0;
            total['avgGrossWeight'] = +totalAvgGrossWeight;
        } else if (total.estimatedHeadCountTotal > 0) {
            const totalEstimatedAvgGrossWeight =
                total.estimatedHeadCountTotal > 0
                    ? parseFloat(
                          (
                              total.estimatedGrossWeightPerHdTotal /
                              total.estimatedHeadCountTotal
                          ).toString(),
                      ).toFixed(2)
                    : 0.0;
            total['avgGrossWeight'] = +totalEstimatedAvgGrossWeight;
        }
    }

    return total['avgGrossWeight'];
};

export const calculateGrandTotal = (type, total) => {
    if (type.includes('totalDollars') || type.includes('feeAmount')) {
        const grandTotal = total.totalDollarsTotal + total.feeAmountTotal;
        total['grandTotal'] = +grandTotal.toFixed(2);
    }

    return total['grandTotal'];
};

//Calculate average gross weight and grand total
export const totalAmountCalculation = (type, values = {}) => {
    const total = { ...values };

    total['avgGrossWeight'] = calculateAvgGrossWeightPerHead(type, total);
    total['grandTotal'] = calculateGrandTotal(type, total);

    return total;
};

export const ordersValidation = (orders, isAnyError) => {
    const orderFinalArr = orders.map(orderDetail => {
        if (!orderDetail.orderName) {
            orderDetail.orderNameError = 'red';
            isAnyError = true;
        }
        if (!orderDetail.headCount && !orderDetail.estimatedHeadCount) {
            orderDetail.headCountError = 'red';
            orderDetail.estimatedHeadCountError = 'red';
            isAnyError = true;
        }
        if (!orderDetail.grossWeight && !orderDetail.payWeight) {
            orderDetail.grossWeightError = 'red';
            orderDetail.payWeightError = 'red';
            isAnyError = true;
        }
        if (!orderDetail.owner) {
            orderDetail.ownerError = 'red';
            isAnyError = true;
        }

        return orderDetail;
    });

    return { ordersCopy: orderFinalArr, isAnyOrdersError: isAnyError };
};

export const shipmentsValidation = (shipments, isAnyError) => {
    const mandatoryFields = [
        { label: 'lot' },
        { label: 'headCount', label2: 'estimatedHeadCount' },
        { label: 'grossWeight', label2: 'estimatedGrossWeightPerHd' },
        { label: 'owner' },
    ];

    const finalShipments = shipments.map(shipment => {
        mandatoryFields.forEach(fieldLabels => {
            if (fieldLabels.label2) {
                //Two field mandatory with 'AND' condition
                if (
                    !shipment[fieldLabels.label] &&
                    !shipment[fieldLabels.label2]
                ) {
                    shipment[`${fieldLabels.label}Error`] = 'red';
                    shipment[`${fieldLabels.label2}Error`] = 'red';
                    isAnyError = true;
                }
            } else {
                if (!shipment[fieldLabels.label]) {
                    shipment[`${fieldLabels.label}Error`] = 'red';
                    isAnyError = true;
                }
            }
        });

        return shipment;
    });

    return { shipmentsCopy: finalShipments, isAnyShipmentsError: isAnyError };
};

const filterLocationAndDestination = (
    locations,
    locationId,
    locationFieldName,
    globalInfoCopy,
) => {
    for (let index = 0; index < locations.length; index++) {
        if (locations[index].id === +locationId) {
            globalInfoCopy[`${locationFieldName}Name`] = locations[index].name;
            break;
        }
    }

    return globalInfoCopy;
};

const filterTruckingCompany = (
    truckingCompanies,
    truckingCompanyId,
    truckingCompanyFieldName,
    globalInfoCopy,
) => {
    for (let index = 0; index < truckingCompanies.length; index++) {
        if (truckingCompanies[index].companyId === +truckingCompanyId) {
            globalInfoCopy[`${truckingCompanyFieldName}Name`] =
                truckingCompanies[index].name;
            break;
        }
    }

    return globalInfoCopy;
};

export const setShipPOLocationNameById = (
    globalInfoKey,
    globalInfoCopy,
    locations,
    truckingCompanies,
) => {
    const globalInfoField = ['location', 'destination'];

    if (globalInfoField.includes(globalInfoKey)) {
        globalInfoCopy = filterLocationAndDestination(
            locations,
            globalInfoCopy[globalInfoKey],
            globalInfoKey,
            globalInfoCopy,
        );
    } else if (globalInfoKey === 'truckingCompany') {
        globalInfoCopy = filterTruckingCompany(
            truckingCompanies,
            globalInfoCopy[globalInfoKey],
            globalInfoKey,
            globalInfoCopy,
        );
    }

    return globalInfoCopy;
};

export const globalInfoValidation = (
    errorFields,
    globalInfoErrorCopy,
    isAnyError,
    globalInfoValues,
) => {
    errorFields.forEach(field => {
        if (!globalInfoValues[field]) {
            globalInfoErrorCopy[field] = 'red';
            isAnyError = true;
        }
    });

    return { globalInfoErrorCopy, isGlobalError: isAnyError };
};

export const setErrorNull = (list, index, key) => {
    list[index][`${key}Error`] = null;
    return list;
};

export const setGlobalInfoErrorNull = (globalInfoError, key) => {
    globalInfoError[key] = null;
    return globalInfoError;
};

export const isAttachFileFormDisabled = fileArray => {
    return fileArray.length >= BuyPOGlobalInfoConstants.totalNOFiles;
};

export const changeNumberWithCommas = (
    value,
    canToFixed = false,
    decimalPlaces = 2,
) => {
    if (value) {
        const num = canToFixed
            ? parseFloat(value).toFixed(decimalPlaces)
            : value;
        const numWithCommas = num
            ?.toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        if (numWithCommas.includes('.')) {
            const splitValue = numWithCommas.split('.');
            splitValue[1] = splitValue[1].replaceAll(',', '');
            const formattedValue = splitValue.join('.');
            return formattedValue;
        } else {
            return numWithCommas;
        }
    } else {
        return 0;
    }
};

export const calculatePayWeight = (grossWeight, shrinkPercentage) => {
    if (!grossWeight || grossWeight < 1) return 0;

    return (grossWeight * (100 - shrinkPercentage)) / 100;
};

export const isValidFileExtension = fileName => {
    if (!fileName) return false;
    const fileExtension = fileName.split('.').pop()?.toLowerCase();
    if (Constants.createBuyPoAllowFileExtension.includes(fileExtension)) {
        return true;
    }
    return false;
};

export const isBrowserMIMEUnsupportedExtension = fileName => {
    if (!fileName) return false;
    const fileExtension = fileName.split('.').pop()?.toLowerCase();
    if (Constants.browserMIMEUnsupportedExtension.includes(fileExtension)) {
        return true;
    }
    return false;
};

export const fetchCanAddMoreFile = (fileDetail: File, files: File[]) => {
    if (isBrowserMIMEUnsupportedExtension(fileDetail.name)) {
        return fileDetail.size <= Constants.validFileSize;
    }
    return (
        Constants.createBuyPoAllowFileType.includes(fileDetail.type) &&
        isValidFileExtension(fileDetail.name) &&
        fileDetail.size <= Constants.validFileSize
    );
};

export const calculateCostPerCWT = ordersAndFeeTotal => {
    if (
        ordersAndFeeTotal.totalDollarsTotal === 0 ||
        ordersAndFeeTotal.payWeightTotal === 0
    ) {
        return 0;
    }
    return (
        Number(ordersAndFeeTotal.totalDollarsTotal) /
        (Number(ordersAndFeeTotal.payWeightTotal) / 100)
    );
};
