import { ICattlePOFeatures } from './interface';
import { getFeatureFlagValue } from '../../common/featureFlags/helpers';

export const cattlePO: ICattlePOFeatures = {
    isBP1146_buypoOrderPriceField: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP1146_BUYPO_ORDER_PRICE_FIELD,
    ),
    isBP1246_buypoFilterQuerySync: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP1246_BUYPO_FILTER_QUERY_SYNC,
    ),
    isBP1185_SupportButton: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP1185_SUPPORT_BUTTON,
    ),
    isBP1063_ShipPOActiveCheckInDropdowns: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP1063_SHIPPO_ACTIVE_CHECK_IN_DROPDOWN,
    ),
    isBP1292_Support_backend_implementation: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP1292_SUPPORT_BACKEND_IMPLEMENTATION,
    ),
    isBP1283_MissingHdEsthdValidation: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP1283_MISSING_HD_ESTHD_VALIDATION,
    ),
};
